import React from 'react'

const Brandbelieve = () => {
  return (
    <div className='container-main'>
        <div className="brand-believe">
            <p className='mb-0 brandbelievtxt'>Brands that believe in us. Brands that have gone the extra mile with us.  </p>
        </div>
    </div>
  )
}

export default Brandbelieve