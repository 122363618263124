import React, { useState, useEffect } from 'react';
import logo from '../../assets/images/logo-baner.png';
import linkedin from '../../assets/linkedin.svg';
import faceebook from '../../assets/facebk.svg';
import insta from '../../assets/insta.svg';
import twiter from '../../assets/twiter.svg';
import { Link } from 'react-router-dom';

const ComingSoon = () => {
  const [text, setText] = useState('');
  const fullText = "ADDING SOON!";

  useEffect(() => {
    let index = 0;
    const interval = setInterval(() => {
      setText(fullText.slice(0, index + 1));
      index++;
      if (index === fullText.length) {
        clearInterval(interval);
      }
    }, 100); // Adjust the speed here
  }, [fullText]);

  return (
    <div className="main-dv-cpming-soon">
      <div className='coming-soon'>
        <div className="logo-company">
          <img src={logo} className='logo-soon' alt="Company Logo" />
        </div>
        <div className="contents-coming-soon abra-font">
          <h1 id="typing-text">{text}</h1>
          <p className='sub-text-coming-soon'>
            Something amazing is on the way! We're working hard behind the scenes to bring you a new and improved website. Stay tuned for updates!
          </p>
          <form>
            <div className="form-group input-coming mb-0">
              <input type="email" className="form-control abra-font" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Email" />
            </div>
            <button className='coming-soon-btn abra-font'>Subscribe</button>
          </form>
        </div>
        <div className="social-media-dvs">
          <Link to="https://www.facebook.com/harisandconsulting"><img src={faceebook} className='links-social' alt="Facebook" /></Link>
          <Link to="https://www.linkedin.com/company/harisand-co"><img src={linkedin} className='links-social' alt="LinkedIn" /></Link>
          <Link to="https://x.com/harisand_co"><img src={twiter} className='links-social' alt="Twitter" /></Link>
          <Link to="https://www.instagram.com/harisand.co/"><img src={insta} className='links-social' alt="Instagram" /></Link>
        </div>
      </div>
    </div>
  );
}
export default ComingSoon;