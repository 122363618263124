import React, { useEffect, useState } from "react";
import user from "../../../assets/Assets/Ellipse 7.svg";
import rn from "../../../assets/r.png";
import img1 from '../../../assets/img1.png';
import img2 from '../../../assets/img2.png';
import img3 from '../../../assets/img3.png';

const reviews = [
  {
    id: 1,
    type: "image",
    name: "Review Image 1",
    url: img1,
    videoUrl: "https://www.youtube.com/embed/LXWGDUwGkEk?si=dMDxPw1m7JdqAM-e"
  },
  {
    id: 2,
    type: "image",
    name: "Review Image 2",
    url: img2,
    videoUrl: "https://www.youtube.com/embed/qhehmRAaO7Y?si=MvtbcJNYoG5IZhOI"
  },
  {
    id: 3,
    type: "image",
    name: "Review Image 3",
    url: img3,
    videoUrl: "https://www.youtube.com/embed/qhehmRAaO7Y?si=MvtbcJNYoG5IZhOI"
  },
];

const Review = ({ review, isVisible, onClick }) => {
  if (review.type === "image") {
    return (
      <div
        className={`w-full max-w-[500px] rounded-2xl bg-black transition-opacity duration-1000 ${isVisible ? "opacity-100" : "opacity-0"
          } absolute right-0`}
        onClick={() => onClick(review)}
      >
        <img
          src={review.url}
          alt={review.name}
          className="w-full h-auto object-cover cursor-pointer"
        />
      </div>
    );
  }

  return null;
};

const VideoPopup = ({ review, onClose }) => {
  if (!review) return null;

  return (
    <div className="fixed inset-0  bg-opacity-70 flex items-center justify-center z-50">
      <div className="relative pno bg-white p-4 rounded-lg">
        <button
          className="absolute top-2 right-2 text-2xl font-bold"
          onClick={onClose}
        >
          &times;
        </button>
        <iframe className="iframe-rv"
          width="560"
          height="315"
          src={review.videoUrl}
          title={`YouTube video for ${review.name}`}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
    </div>
  );
};

const Clients = () => {
  const [visibleReview, setVisibleReview] = useState(0);
  const [isDelayed, setIsDelayed] = useState(false);
  const [selectedReview, setSelectedReview] = useState(null);

  useEffect(() => {
    const changeReview = () => {
      setIsDelayed(true);
      setTimeout(() => {
        setVisibleReview((prev) => (prev + 1) % reviews.length);
        setIsDelayed(false);
      }, 800);
    };

    const interval = setInterval(changeReview, 4000);

    return () => clearInterval(interval);
  }, []);

  const handleImageClick = (review) => {
    setSelectedReview(review);
  };

  const handleClosePopup = () => {
    setSelectedReview(null);
  };

  return (
    <div className="clients-reviews py-8">
      <div className="container-main mx-auto">
        <div className="flex flex-col md:flex-row items-center justify-between">
          <div className="w-full md:w-1/2 mb-8 md:mb-0">
            <div className="review-main">
              <h2 className="clients-heading mb-2 abra-font">Client Reviews</h2>
              <p className="mb-4 clients-sub">
                Here is what our clients have to say about our service.
              </p>
              <img src={rn} className="clients-logo" alt="" />
            </div>
          </div>

          <div className="relative w-full md:w-1/2 h-[250px]">
            {reviews.map((review, index) => (
              <Review
                key={review.id}
                review={review}
                isVisible={index === visibleReview && !isDelayed}
                onClick={handleImageClick}
              />
            ))}
          </div>
        </div>
      </div>
      <VideoPopup review={selectedReview} onClose={handleClosePopup} />
    </div>
  );
};
export default Clients;