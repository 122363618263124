import React from 'react'
import teamsocialmedia from '../../assets/team-social-medoa.svg'
const SocialMediaTeam = () => {
	return (
		<div className='container-main'>
			<div className="row">
				<div className="col-lg-3">
					<h2 className='heading-social-media-team abra-font'>Lorem ipsum dolor sit amet lamet</h2>
				</div>
				<div className="col-lg-9">
					<div className="main-sub-social">
						<div className="sub-div-social">
								<h2 className='sub-text-social-media'>99% of SEO companies give you ranks. Just 1% brings you revenue-inspiring results. We fall in the second category.</h2>
						</div>
					</div>
				</div>
				<div className="col-lg-3">
					<div className="sub-items-social-media">
						<div className="item-dv-scc">
							<h4 className='title-social abra-font'>Lorem Ipsum</h4>
							<h5 className='abra-font sub-title-social'>Lorem Ipsum dolor sit amet amet sit dolor</h5>
						</div>
						<div className="item-dv-scc">
							<h4 className='title-social abra-font'>Lorem Ipsum</h4>
							<h5 className='abra-fonzzt sub-title-social'>Lorem Ipsum dolor sit amet amet sit dolor</h5>
						</div>
						<div className="item-dv-scc">
							<h4 className='title-social abra-font'>Lorem Ipsum</h4>
							<h5 className='abra-font sub-title-social'>Lorem Ipsum dolor sit amet amet sit dolor</h5>
						</div>
						<div className="item-dv-scc">
							<h4 className='title-social abra-font'>Lorem Ipsum</h4>
							<h5 className='abra-font sub-title-social'>Lorem Ipsum dolor sit amet amet sit dolor</h5>
						</div>
						<div className="item-dv-scc">
							<h4 className='title-social abra-font'>Lorem Ipsum</h4>
							<h5 className='abra-font sub-title-social'>Lorem Ipsum dolor sit amet amet sit dolor</h5>
						</div>
					</div>
				</div>
				<div className="col-lg-9">
					<div className="main-sub-social">
						<div className="sub-div-socials">
								<img src={teamsocialmedia} className='social-media-imggg' alt="" />
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
export default SocialMediaTeam;