import React from "react";
import "swiper/css"; // Import Swiper styles
import "swiper/css/autoplay";
import awardone from "../../../assets/Assets/3 startup award.png";
import awardtwo from "../../../assets/Assets/brand storyz.png";
import awardthree from "../../../assets/Assets/pepper award.png";
import awardfour from "../../../assets/Assets/startup brands bronze.png";
import a from '../../../assets/Assets/amna 2.png'
import { Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css'; // Import Swiper styles
const Frame = () => {
  return (
    <div className="frame-section container-main">
      <div className="row">
        <div className="col-lg-12">
          <div className="frame-main flex items-center justify-between">
            <div className="frame-awards">
              <h2 className="mb-0 wall-text abra-font">
                The Wall of Fame
              </h2>
              <p className="mb-0 wall-sub-text">
                Our awards and recognitions
              </p>
              <div className="mt-4 swipeeraward">
                <Swiper
                  centeredSlides
                  loop = {true}
									autoplay={{
										delay: 2000,
										disableOnInteraction: false,
									}}
									modules={[Autoplay]}
                  spaceBetween={10} 
                  slidesPerView={3}
                  initialSlide={1}
                >
									<SwiperSlide>
										<img src={awardone} alt="" />
										<p className="award-text">afaqs! Startup Brands Award - Best Moment Marketing										</p>
									</SwiperSlide>
									<SwiperSlide>
										<img src={awardtwo} alt="" />
										<p className="award-text"> afaqs! Brand Storyz Award - Best Lead Generation Campaign										</p>
									</SwiperSlide>
									<SwiperSlide>
										<img src={awardthree} alt="" />
										<p className="award-text">  Pepper Awards - Best Single Online Video										</p>
									</SwiperSlide>
									<SwiperSlide>
										<img src={awardfour} alt="" />
										<p className="award-text"> afaqs! Startup Brands Award - Best Social Media Campaign										</p>
									</SwiperSlide>
									<SwiperSlide>
										<img src={awardone} alt="" />
										<p className="award-text">afaqs! Startup Brands Award - Best Branded Content</p>
									</SwiperSlide>
									<SwiperSlide>
										<img src={awardone} alt="" />
										<p className="award-text"> afaqs! Startup Brands Award - Best Use of Video on Social Media										</p>
									</SwiperSlide>

									{/* Add more slides as needed */}
								</Swiper>
							</div>
						</div>
						<div className="fameimg">
							{/* Your image content here */}
							<img src={a} alt="amna" />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Frame;
