import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
const SeaProposal = () => {
	const [mouseOverProposal, setMouseOverProposal] = useState(false);
  const [mouseOverConsult, setMouseOverConsult] = useState(false);
  const [currentImage, setCurrentImage] = useState(0);
  const [mouseOver, setMouseOver] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [result, setResult] = React.useState("");



	const onSubmit = async (event) => {
	  event.preventDefault();
	  setResult("Sending....");
	  const formData = new FormData(event.target);
  
	  formData.append("access_key", "f94f2cd3-c0a3-494b-a2d4-ca767a1fd557");
  
	  const response = await fetch("https://api.web3forms.com/submit", {
		method: "POST",
		body: formData
	  });
  
	  const data = await response.json();
  
	  if (data.success) {
		setResult("Form Submitted Successfully");
		event.target.reset();
	  } else {
		console.log("Error", data);
		setResult(data.message);
	  }
	};



	return (
		<div className='container-main'>
			<div className="proposal-main">
				<h2 className='get-seo-text abra-font szs'>Get a Free SEO Audit & Proposal</h2>
				<p className='get-sub-text sx'>Get an overview of your website’s SEO performance and a proposal <span>including our detailed SEO plan.</span>  </p>
					<div className="form-proposal">
					<form action="https://api.web3forms.com/submit" method="POST">

							<input type="hidden" name="access_key" value="YOUR_ACCESS_KEY_HERE"/>
							<div class="form-group">
								<input type="text" name="Name" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Your Name"/>
							</div>
							<div class="form-group">
								<input type="email" name="Email"  class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Email"/>
							</div>
							<div class="form-group">
								<input type="text" name="Website Name"  class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Website"/>
							</div>

						</form>
						<div className="btn-proposal">
						{/* <button className="submit-button-form btn abra-font" type="submit">Submit Now</button> */}

							<Link onMouseOver={() => setMouseOverProposal(true)} onMouseLeave={() => setMouseOverProposal(false)} className="btn proposal-btn primary-bg abra-font" onClick={() => setShowModal(true)} >Get It {mouseOverProposal ? <ArrowForwardIcon /> : <ArrowOutwardIcon />}</Link>
						</div>
					</div>
			</div>
		</div>
	)
}
export default SeaProposal;