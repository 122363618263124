import { BrowserRouter, Route, Routes } from 'react-router-dom';
import * as ReactDOM from "react-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import './index.css';
import './styles.css';
import Cursor from './components/CursorAnimation/Cursor';
import Footer from './components/Footer/Footer';
import HomeMain from './components/Home/HomeMain';
import { Rotate } from 'hammerjs';
import AboutUs from './components/About/AboutUs';
import Service from './components/Service/Service';
import Blog from './components/Blog/Blog';
import People from './components/People/people'
import Home from './components/Seo/Home';
import Contactpage from './components/Contact/Contactpage';
import Seo from './components/SeoLandingPage/Seo';
import { Link } from 'react-router-dom';
import SocialMedia from './components/SocialMedia/SocialMedia';
import ComingSoon from './components/ComingSoon/ComingSoon';
// import Footer from './Outlets/Footer';
function App() {
  return (
    <div className="App">
      
      <BrowserRouter>
           <Cursor/>
        <Routes>
            <Route exact path   = "/" element={<ComingSoon/>}/>
            <Route  path   = "/aboutus" element={<AboutUs/>}/>
            {/* <Route  path   = "/soon" element={<HomeMain/>}/> */}
            <Route  path   = "/service" element={<Service/>}/>
            <Route  path   = "/blog" element={<Blog/>}/>
            <Route  path   = "/socialmedia" element={<SocialMedia/>}/>
            <Route  path   = "/People" element={<People/>}/>
            <Route  path   = "/Contactpage" element={<Contactpage/>}/>
            <Route  path   = "/digital-marketing-agency-in-kerala" element={<Home/>}/>
            <Route  path   = "/seo-company-in-kerala" element={<Seo/>}/>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
