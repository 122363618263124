import React from 'react'
import { Container, Row } from 'react-bootstrap'
import './peopledepartmentheads.css'
import Peopleimg from '../.././../assets/imagIndex'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from 'react-slick'

export default function PeopleDepartmentHeads() {
	var PeopleDepartmentHeadsSlide = {
		dots: false,
		infinite: true,
		slidesToShow: 4,
		slidesToScroll: 1,
		arrows: false,
		autoplay: true,
		speed: 500,
		centerMode: true,
		autoplaySpeed: 2000,
		responsive: [
			{
				breakpoint: 1199,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 1,
				}
			},
			{
				breakpoint: 991,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1
				}
			},
			{
				breakpoint: 767,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1
				}
			}
		]
	};
	return (
		<div className='departmentheads'>
			<Container>
				<div className='departmentheads-content-wrper'>
					<div className='departmentheads-hd'>
						Our Department Heads
					</div>
					<div className='departmentheads-subhd'>
						Meet the minds <br /> shaping our talents.
					</div>
				</div>
				<div className='departmentheadsimage-boxwrpper' >
					<Slider className='departmentheadsimage-slider' {...PeopleDepartmentHeadsSlide}>
						<div className='departmentheadsimage-slider-items'>
							<div className='departmentheadsimage-slider-item'>
								<div className='departmentheadsimage-box'>
									<img loading="lazy" src={Peopleimg.peopleDepartmentHeadimgAmna} alt='Logo' />
								</div>
								<div className='departmentheadsimage-content'>
									<div className='departmentheadsimage-name'>
										Amna Iqbal
									</div>
									<div className='departmentheadsimage-position'>
										Chief of Social Media
									</div>
								</div>
							</div>
						</div>

						<div className='departmentheadsimage-slider-items'>
							<div className='departmentheadsimage-slider-item'>
								<div className='departmentheadsimage-box'>
									<img loading="lazy" src={Peopleimg.peopleDepartmentHeadimgNabhan} alt='Logo' />
								</div>

								<div className='departmentheadsimage-content'>
									<div className='departmentheadsimage-name'>
										Abu Nabhan
									</div>
									<div className='departmentheadsimage-position'>
										Chief of Branding
									</div>
								</div>
							</div>
						</div>

						<div className='departmentheadsimage-slider-items'>
							<div className='departmentheadsimage-slider-item'>
								<div className='departmentheadsimage-box'>
									<img loading="lazy" src={Peopleimg.peopleDepartmentHeadimgNihal} alt='Logo' />
								</div>
								<div className='departmentheadsimage-content'>
									<div className='departmentheadsimage-name'>
										Nihal Zubair
									</div>
									<div className='departmentheadsimage-position'>
										Chief of Creative
									</div>
								</div>
							</div>
						</div>

						<div className='departmentheadsimage-slider-items'>
							<div className='departmentheadsimage-slider-item'>
								<div className='departmentheadsimage-box'>
									<img loading="lazy" src={Peopleimg.peopleDepartmentHeadimgvishnu} alt='Logo' />
								</div>

								<div className='departmentheadsimage-content'>
									<div className='departmentheadsimage-name'>
										Vishnu KP
									</div>
									<div className='departmentheadsimage-position'>
										Chief of Production
									</div>
								</div>
							</div>
						</div>

						<div className='departmentheadsimage-slider-items'>
							<div className='departmentheadsimage-slider-item'>
								<div className='departmentheadsimage-box'>
									<img loading="lazy" src={Peopleimg.peopleDepartmentHeadimgraginhd} alt='Logo' />
								</div>

								<div className='departmentheadsimage-content'>
									<div className='departmentheadsimage-name'>
										Ragin Raj
									</div>
									<div className='departmentheadsimage-position'>
										Chief of SEO
									</div>
								</div>
							</div>
						</div>
						<div className='departmentheadsimage-slider-items'>
							<div className='departmentheadsimage-slider-item'>
								<div className='departmentheadsimage-box'>
									<img loading="lazy" src={Peopleimg.peopleDepartmentHeadimgAdarshhd} alt='Logo' />
								</div>
								<div className='departmentheadsimage-content'>
									<div className='departmentheadsimage-name'>
										Adarsh MS
									</div>
									<div className='departmentheadsimage-position'>
										Chief of Performance Marketing
									</div>
								</div>
							</div>
						</div>
						<div className='departmentheadsimage-slider-items'>
							<div className='departmentheadsimage-slider-item'>
								<div className='departmentheadsimage-box'>
									<img loading="lazy" src={Peopleimg.peopleDepartmentHeadimgShamlahd} alt='Logo' />
								</div>
								<div className='departmentheadsimage-content'>
									<div className='departmentheadsimage-name'>
										Fathim Shamla
									</div>
									<div className='departmentheadsimage-position'>
										Chief of PR&Communication
									</div>
								</div>
							</div>
						</div>
						<div className='departmentheadsimage-slider-items'>
							<div className='departmentheadsimage-slider-item'>
								<div className='departmentheadsimage-box'>
									<img loading="lazy" src={Peopleimg.peopleDepartmentHeadimgarjunhd} alt='Logo' />
								</div>
								<div className='departmentheadsimage-content'>
									<div className='departmentheadsimage-name'>
										Arjun P
									</div>
									<div className='departmentheadsimage-position'>
										Chief of Web Development
									</div>
								</div>
							</div>
						</div>
					</Slider>
				</div>
			</Container>
		</div>
	)
};