import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import CountUp from "../custom/CountUp";
import ArrowForwardIcon  from "@mui/icons-material/ArrowForward";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
const About = () => {
  const [mouseOver, setMouseOver] = useState(false);

  const [currentImage, setCurrentImage] = useState(0); // State to track current image index

  const images = ["./assets/founder.svg"];

  useEffect(() => {
    // Function to change image every 3 seconds
    const interval = setInterval(() => {
      setCurrentImage((prevIndex) => (prevIndex + 1) % images.length);
    }, 5000); // Change image every 3000 milliseconds (3 seconds)

    return () => clearInterval(interval); // Clean up interval on component unmount
  }, [images.length]); // Dependency array to ensure interval is set correctly

  return (
    <div className="about-main-section container-main">
      <div className="lg:flex lg:flex-row flex-col items-start ">
        <div className="md:w-[60%] w-[100%]">
          <div className="about-main">
            <h2 className="mb-0 about-main-heading abra-font">
              Fearless & Authentic Digital{" "}
              <span className="abra-font block-text">
                Marketing Since Birth.
              </span>
            </h2>
            <p className="mb-0 sub-text-aboutus">
              We've come a long way ever since we started our journey in 2020 as
              a digital marketing agency in kerala with just 6 members and a handful of
              clients. Today, we're 100+ members strong, organised into 8
              departments, with a huge (and satisfied) clientele. The work
              culture, results and innovation are at the core of who we are.
            </p>
            <Link
              onMouseOver={() => setMouseOver(true)}
              onMouseLeave={() => setMouseOver(false)}
              className="btn learn-btn primary-bg abra-font mobile-none"
              to="/about"
            >
              Learn More About Us
              {mouseOver ? <ArrowForwardIcon /> : <ArrowOutwardIcon />}
            </Link>
          </div>
        </div>
        <div className="md:w-[40%] w-[100%] flex mobile-flex items-center md:justify-end justify-start md:mt-0 mt-4">
          <div className="">
            <div className="count-block">
              <h2 className="flex fc flex-col abra-font mb-0 count-text">
                2020{" "}
                <span className="block-text abra-font count-small">
                  Established Year
                </span>
              </h2>
            </div>
            <div className="count-block">
              <h2 className="flex fc abra-font fw mb-0 count-text">
                <CountUp duration={1000} target={100} />
                <span className="xs:text-sm abra-font plussicon">+</span>
              </h2>
              <span className="plussicon  block-text abra-font count-small">
                Employees
              </span>
            </div>
            <div className="count-block">
              <h2 className=" flex fc abra-font fw mb-0 count-text">
                <CountUp duration={1000} target={8} />
              </h2>
              <span className="  block-text abra-font count-small">
                Departments
              </span>
            </div>
            <Link
              onMouseOver={() => setMouseOver(true)}
              onMouseLeave={() => setMouseOver(false)}
              className="btn lbb learn-btn primary-bg abra-font mobile-block desknone"
              to="/about"
            >
              Learn More About Us
              {mouseOver ? <ArrowForwardIcon /> : <ArrowOutwardIcon />}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
