import React, { useState } from "react";
// import logo from "../Assets/logo.svg";
import logo from "../../src/assets/Assets/logo.svg";
import { Link } from "react-router-dom";
import { ArrowForward } from "@mui/icons-material";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import { Modal, Button } from 'react-bootstrap'; // Import from react-bootstrap
// import modalimage from '.././Assets/popupweb.png'
import modalimage from '../../src/assets/Assets/popupweb.png'
// import modalimagemobile from '.././Assets/popupmob.webp'
import modalimagemobile from '../../src/assets/Assets/popupmob.webp'
// import menu from '.././Assets/menubar.svg'
import menu from '../../src/assets/Assets/menubar.svg'
const Navbar = () => {
  const [mouseOver, setMouseOver] = useState(false);
  const [showModal, setShowModal] = useState(false);       

  const [result, setResult] = React.useState("");

	const onSubmit = async (event) => {
	  event.preventDefault();
	  setResult("Sending....");
	  const formData = new FormData(event.target);
  
	  formData.append("access_key", "f94f2cd3-c0a3-494b-a2d4-ca767a1fd557");
	  const response = await fetch("https://api.web3forms.com/submit", {
		method: "POST",
		body: formData
	  });
  
	  const data = await response.json();
  
	  if (data.success) {
		setResult("Form Submitted Successfully");
		event.target.reset();
	  } else {
		console.log("Error", data);
		setResult(data.message);
	  }
	};

  return (
    <div className="nav-main black-bg hide-mob-land nav-show-home" >
      <div className="container-main">
        <div className="navbar-main">
          <a className="logo-nav" href="/">
            <img src={logo} className="logo-main" alt="" />
          </a>
          <div className="item-nav">
            <ul className="mb-0 nav-item-list-dv">
              <li>
                <Link className="nav-item active-nav" to="/">
                  Home
                </Link>
              </li>
              <li>
                <Link className="nav-item" to="/service">
                  Services
                </Link>
              </li>
              <li>
                <Link className="nav-item" to="/">
                  Culture
                </Link>
              </li>
              <li>
                <Link className="nav-item" to="/">
                  Case Studies
                </Link>
              </li>
              <li>
                <Link className="nav-item" to="/Contactpage">
                  Contact Us
                </Link>
              </li>
            </ul>
          </div>
          <div
            onMouseOver={() => setMouseOver(true)}
            onMouseLeave={() => setMouseOver(false)}
            className="btn-nav"
          >
            <Link
              className="btn proposal-btn primary-bg abra-font"
              onClick={() => setShowModal(true)} // Trigger the modal on click
            >
              GET A PROPOSAL{" "}
              {mouseOver ? (
                <ArrowForward />
              ) : (
                <ArrowOutwardIcon />
              )}
            </Link>
          </div>
          <img src={menu} className="menu-icon" alt="" />
        </div>
      </div>

      <Modal className="modalmaster" size="lg" show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Body>
            <div className="modal-main-div">
              <div className="modal-half">
                <img src={modalimage} className="popimage" alt="" />
                <img src={modalimagemobile} className="popimagemobile" alt="" />
              </div>
              <div className="modal-half-p">
                <div className="modal-contentt">
                <i class="ri-close-circle-line closeicon" onClick={() => setShowModal(false)}></i>
                  <h3 className="callback abra-font">We'll Call You Back</h3>
                  <p className="popup-subtext abra-font">Our sales team will contact you soon to gather the <br /> details needed to prepare your proposal.</p>
                  <div className="modal-form">
                    <form onSubmit={onSubmit}>
                      <div className="form-group form-half-popup">
                        <label for="exampleInputEmail1">Full Name</label>
                        <input type="text" name="Name" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder=""/>
                      </div>
                      <div className="form-group form-half-popup">
                        <label for="exampleInputEmail1">Company*</label>
                        <input type="text" name="Company" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder=""/>
                      </div>
                      <div className="form-group form-half-popup">
                        <label for="exampleInputEmail1">Phone*</label>
                        <input type="text"  name="Phone" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder=""/>
                      </div>
                      <div className="form-group form-half-popup">
                        <label for="exampleInputEmail1">Company email*</label>
                        <input type="email" name="mail" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder=""/>
                      </div>
                      <div class="form-group form-textarea">
                        <label for="exampleFormControlTextarea">Message*</label>
                        <textarea class="form-control mt-2" id="exampleFormControlTextarea1" rows="4"></textarea>
                      </div>
                    <button className="submit-button-form btn abra-font" type="submit">Submit Now</button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Navbar;