import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import resultsmain from '../../../assets/Group 1000006066.svg'
import teammob from '../../../assets/Group 1000006067.svg'


const Results = () => {

	const [mouseOverProposal, setMouseOverProposal] = useState(false);
	const [mouseOverConsult, setMouseOverConsult] = useState(false);
	const [currentImage, setCurrentImage] = useState(0);
	// const [showModal, setShowModal] = useState(false);


	const [mouseOver, setMouseOver] = useState(false);
	const [showModal, setShowModal] = useState(false);

	const [result, setResult] = React.useState("");


  return (
    <div className='container-main-large'>
      <div className="results-main-dv">
				<h2 className='results-main-text abra-font'>Don't Believe Us?  <span className='abra-font'>You Can Believe Our Results</span></h2>
				<img src={resultsmain} className='result-large-image' alt="" />
        <img src={teammob} className='mob-team-seo' alt="" />
				<div className="arrow-reults">
          <Link
              onMouseOver={() => setMouseOverConsult(true)}
              onMouseLeave={() => setMouseOverConsult(false)}
              className="btn proposal-btn abra-font consult-bttn"
              to="https://wa.me/+918075040330"
            >
              Check Out Our Case Studies
              {mouseOverConsult ? <ArrowForwardIcon /> : <ArrowOutwardIcon />}
            </Link>
        </div>
			</div>
    </div>
  )
}

export default Results