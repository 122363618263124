import React, { useEffect, useState } from "react";
import servicelogoone from "../../../assets/Assets/seo logo 1.svg";
import servicelogotwo from "../../../assets/Assets/Performance 1.svg";
import servicelogothree from "../../../assets/Assets/Social media (1) 1.svg";
import servicelogofour from "../../../assets/Assets/Web (1) 1.svg";
import servicelogofive from "../../../assets/Assets/Branding (1) 1.svg";
import team from "../../../assets/Assets/seom.webp";
import social from '../../../assets/Assets/socialm.webp'
import perfomance from '../../../assets/Assets/performancem.webp'
import web from '../../../assets/Assets/webm.webp'
import branding from '../../../assets/Assets/brandingm.webp'
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import AccordionItem from "../custom/AccordionItem";
import CallReceivedIcon from "@mui/icons-material/CallReceived";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import { Link } from "react-router-dom";
import arrow from "../../../assets/Assets/Vector (3).svg";
const Whatwe = () => {
  const [mouseOver, setMouseOver] = useState(false);

  const [currentImage, setCurrentImage] = useState(0); // State to track current image index

  const images = ["./assets/founder.svg"];

  useEffect(() => {
    // Function to change image every 3 seconds
    const interval = setInterval(() => {
      setCurrentImage((prevIndex) => (prevIndex + 1) % images.length);
    }, 5000); // Change image every 3000 milliseconds (3 seconds)

    return () => clearInterval(interval); // Clean up interval on component unmount
  }, [images.length]); // Dependency array to ensure interval is set correctly

  const [mouseHover, setMouseHover] = useState(false);

  const [accordionStates, setAccordionStates] = useState([
    true,
    false,
    false,
    false,
    false,
  ]);

  return (
    <div className="lg:top-[-300px] lg:sticky lg:z-10 container-main">
      <div className="services-section">
        <h2 className="service-heading-text mb-0 abra-font">
          What We Have for You
        </h2>
        <div className="tab-div">
          <Tab.Container id="left-tabs-example" defaultActiveKey="first">
            <div className="sub-tab">
              <Nav variant="pills" className="">
                <Nav.Item>
                  <Nav.Link eventKey="first">
                    <AccordionItem
                      state={accordionStates[0] === true}
                      onClick={() => {
                        if (accordionStates[0] === true)
                          setAccordionStates([
                            false,
                            false,
                            false,
                            false,
                            false,
                          ]);
                        else
                          setAccordionStates([
                            true,
                            false,
                            false,
                            false,
                            false,
                          ]);
                      }}
                      title={
                        <div className="flex items-center">
                          <img
                            src={servicelogoone}
                            className="service-logo"
                            alt=""
                          />
                          <h4 className=" mb-0 abra-font service-text">
                            SEO
                          </h4>
                        </div>
                      }
                    >
                      <p className="subtextwhatwedo">
                      To appear on top of Google search results and get more website visitors, leads and revenue.   

                      </p>
                    </AccordionItem>
                  </Nav.Link>
                </Nav.Item>

                <Nav.Item>
                  <Nav.Link eventKey="second">
                    <AccordionItem
                      state={accordionStates[1] === true}
                      onClick={() => {
                        if (accordionStates[1] === true)
                          setAccordionStates([
                            false,
                            false,
                            false,
                            false,
                            false,
                          ]);
                        else
                          setAccordionStates([
                            false,
                            true,
                            false,
                            false,
                            false,
                          ]);
                      }}
                      title={
                        <div className="flex items-center">
                          <img
                            src={servicelogotwo}
                            className="service-logo-small"
                            alt=""
                          />
                          <h4 className=" mb-0 abra-font service-text">
                            Performance Marketing
                          </h4>
                        </div>
                      }
                    >
                      <p className=" subtextwhatwedo">
                      To gain measurable results that drive conversions and maximise your ROI with Digital Ads. 
                      </p>
                    </AccordionItem>
                  </Nav.Link>
                </Nav.Item>

                <Nav.Item>
                  <Nav.Link eventKey="tree">
                    <AccordionItem
                      state={accordionStates[2] === true}
                      onClick={() => {
                        if (accordionStates[2] === true)
                          setAccordionStates([
                            false,
                            false,
                            false,
                            false,
                            false,
                          ]);
                        else
                          setAccordionStates([
                            false,
                            false,
                            true,
                            false,
                            false,
                          ]);
                      }}
                      title={
                        <div className="flex items-center">
                          <img
                            src={servicelogothree}
                            className="service-logo-small"
                            alt=""
                          />
                          <h4 className=" mb-0 abra-font service-text">
                            Social Media
                          </h4>
                        </div>
                      }
                    >
                      <p className=" subtextwhatwedo">
                      To build brand awareness, retain customers, and generate more leads on Social Media.

                      </p>
                    </AccordionItem>
                  </Nav.Link>
                </Nav.Item>

                <Nav.Item>
                  <Nav.Link eventKey="four">
                    <AccordionItem
                      state={accordionStates[3] === true}
                      onClick={() => {
                        if (accordionStates[3] === true)
                          setAccordionStates([
                            false,
                            false,
                            false,
                            false,
                            false,
                          ]);
                        else
                          setAccordionStates([
                            false,
                            false,
                            false,
                            true,
                            false,
                          ]);
                      }}
                      title={
                        <div className="flex items-center">
                          <img
                            src={servicelogofour}
                            className="service-logo-small"
                            alt=""
                          />
                          <h4 className=" mb-0 abra-font service-text">
                            Web Development
                          </h4>
                        </div>
                      }
                    >
                      <p className="subtextwhatwedo">  For a website that combines engaging UI, scroll-stopping content and user-friendliness. </p>
                    </AccordionItem>
                  </Nav.Link>
                </Nav.Item>

                <Nav.Item>
                  <Nav.Link style={{ width: "100%" }} eventKey="five">
                    <AccordionItem
                      state={accordionStates[4] === true}
                      onClick={() => {
                        if (accordionStates[4] === true)
                          setAccordionStates([
                            false,
                            false,
                            false,
                            false,
                            false,
                          ]);
                        else
                          setAccordionStates([
                            false,
                            false,
                            false,
                            false,
                            true,
                          ]);
                      }}
                      title={
                        <div className="flex items-center">
                          <img
                            src={servicelogofive}
                            className="service-logo-small"
                            alt=""
                          />
                          <h4 className=" mb-0 abra-font service-text">
                            Branding
                          </h4>
                        </div>
                      }
                    >
                      <p className=" subtextwhatwedo">
                      To help you change the face of your brand, set a new standard and stand out from the crowd. 

                      </p>
                    </AccordionItem>
                  </Nav.Link>
                </Nav.Item>
                <Link
                  onMouseOver={() => setMouseOver(true)}
                  onMouseLeave={() => setMouseOver(false)}
                  className="btn learn-btn primary-bg abra-font ml-5"
                  to="/service"
                >
                  View More
                  {mouseOver ? <ArrowForwardIcon /> : <ArrowOutwardIcon />}
                </Link>
              </Nav>
            </div>
            <div className="sub-tab hidden md:block">
              <Tab.Content>
                <Tab.Pane eventKey="first" className="relative">
                  {mouseHover && (
                    <div className="absolute right-0 top-0 flex items-center justify-end">
                      <img src={arrow} alt="" />
                    </div>
                  )}
                  <img
                    onMouseEnter={(e) => setMouseHover(true)}
                    onMouseLeave={(e) => setMouseHover(false)}
                    src={team}
                    className="seo-team-png"
                    alt=""
                  />
                </Tab.Pane>
                <Tab.Pane eventKey="second" className="relative">
                {mouseHover && (
                    <div className="absolute right-0 top-0 flex items-center justify-end">
                      <img src={arrow} alt="" />
                    </div>
                  )}
                  <img
                    onMouseEnter={(e) => setMouseHover(true)}
                    onMouseLeave={(e) => setMouseHover(false)}
                    src={perfomance}
                    className="seo-team-png"
                    alt=""
                  />
                </Tab.Pane>
                <Tab.Pane className="relative" eventKey="tree">
                {mouseHover && (
                    <div className="absolute right-0 top-0 flex items-center justify-end">
                      <img src={arrow} alt="" />
                    </div>
                  )}
                  <img
                    onMouseEnter={(e) => setMouseHover(true)}
                    onMouseLeave={(e) => setMouseHover(false)}
                    src={social}
                    className="seo-team-png"
                    alt=""
                  />
                </Tab.Pane>
                <Tab.Pane className="relative" eventKey="four">
                {mouseHover && (
                    <div className="absolute right-0 top-0 flex items-center justify-end">
                      <img src={arrow} alt="" />
                    </div>
                  )}
                  <img
                    onMouseEnter={(e) => setMouseHover(true)}
                    onMouseLeave={(e) => setMouseHover(false)}
                    src={web}
                    className="seo-team-png"
                    alt=""
                  />
                </Tab.Pane>
                <Tab.Pane className="relative" eventKey="five">
                {mouseHover && (
                    <div className="absolute right-0 top-0 flex items-center justify-end">
                      <img src={arrow} alt="" />
                    </div>
                  )}
                  <img
                    onMouseEnter={(e) => setMouseHover(true)}
                    onMouseLeave={(e) => setMouseHover(false)}
                    src={branding}
                    className="seo-team-png"
                    alt=""
                  />
                </Tab.Pane>
              </Tab.Content>
            </div>
          </Tab.Container>
        </div>
      </div>
    </div>
  );
};

export default Whatwe;