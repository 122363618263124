import React from "react";
import { CCollapse } from "@coreui/react";
import  one from "../../../assets/seo 1st sec.png";
import two from "../../../assets/seo 2nd sec.png";
import three from "../../../assets/seo 3rd sec.png";
// import four from "../../../assets/Assets/white-logo/4th sectn.png";
// import five from "../../../assets/Assets/white-logo/5th sectn.png";
// import six from "../../../assets/Assets/white-logo/6th sectn.png";
// import seven from "../../../assets/Assets/white-logo/7th sectn.png";
// import eight from "../../../assets/Assets/white-logo/8th sectn.png";
import { useState } from "react";

const initialLogos = [
	{ id: 1, src: one, alt: "1" },
	{ id: 2, src: two, alt: "2" },

];

const collapsedLogos = [
	{ id: 6, src: three, alt: "Logo 6" },
	// { id: 7, src: four, alt: "Logo 7" },
	// { id: 8, src: five, alt: "Logo 8" },
	// { id: 9, src: six, alt: "Logo 9" },

	// { id: 13, src: seven, alt: "Logo 13" },

	// { id: 16, src: eight, alt: "Logo 16" },
	
];

const Brands = () => {
	const [visible, setVisible] = useState(false); // Initial visibility state

  const toggleVisibility = () => {
    setVisible(!visible); // Update visibility on click
  };
	
	const renderBrandLogos = (logos) => {
		return logos.map((logo) => (
			<img
				src={logo.src}
				className="brand-logo"
				alt={logo.alt}
				key={logo.id}
			/>
		));
	};

	return (
<div className="container-main">
      <div className="col-lg-12">
        <div className="brand-main">
          <div className="brands-section">
            {renderBrandLogos(initialLogos)}
          </div>
          <CCollapse visible={visible}>
            <div className="brands-section">
              {renderBrandLogos(collapsedLogos)}
            </div>
          </CCollapse>
          <div className="dropdown-button">
            <p className="mb-0 brand-dropdown-txt">
              The brands that have tasted success with the power of our SEO.{" "}
              <button
                className="view-all text-white btn"
                onClick={toggleVisibility}
              >
                {visible ? "View Less" : "View All"}
              </button>
            </p>
          </div>
        </div>
      </div>
    </div>
	);
};

export default Brands;
