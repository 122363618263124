import React from 'react'
import './peoplebanermob.css'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Peopleimg from '../../../src/assets/imagIndex'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from 'react-slick'

export default function PeopleBannerMob() {
    var PeopleBanerMobSlide = {
        dots: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows:false,
        fade: false,
        cssEase: 'linear',
        autoplay: true,
        speed: 500,
        autoplaySpeed: 2000,
      
      };
  return (
    <div className='peoplebanermob'>
        <div className='peoplebaner-heading-wrpper'>
            <div className='peoplebaner-heading-wrpper-hd'>
                Our Team
            </div>
            <div className='peoplebaner-heading-wrpper-txt'>
                 Creating leaders of tomorrow
            </div>
        </div>
        <div className='peoplebaner-slider'>
            <Slider {...PeopleBanerMobSlide} className='slider'>
                <div className='peoplebanersliderslide-item'>
                    <div className='peoplebaner-slider-wrper'> 
                        <div className='peoplebanerslide-item'>
                        <img loading="lazy" src={Peopleimg.peopleBanerimgHaris} alt='Logo'/>  
                            <div className='peoplebanerslide-item-name haris'>
                            Haris <br/> Aboobacker
                            </div>  
                        </div>
                    </div>
                </div>
                <div className='peoplebanersliderslide-item'>
                    <div className='peoplebaner-slider-wrper'> 
                        <div className='peoplebanerslide-item'>
                            <img loading="lazy" src={Peopleimg.peopleBanerimgAdarsh} alt='Logo'/>  
                            <div className='peoplebanerslide-item-name'>
                            Adarsh
                            </div>  
                        </div>
                    </div>
                </div>
                <div className='peoplebanersliderslide-item'>
                    <div className='peoplebaner-slider-wrper'> 
                        <div className='peoplebanerslide-item'>
                        <img loading="lazy" src={Peopleimg.peopleBanerimgRagin} alt='Logo'/>    
                            <div className='peoplebanerslide-item-name'>
                            Ragin
                            </div>  
                        </div>
                    </div>
                </div>
                <div className='peoplebanersliderslide-item'>
                    <div className='peoplebaner-slider-wrper'> 
                        <div className='peoplebanerslide-item'>
                        <img loading="lazy" src={Peopleimg.peopleBanerimgSaheel} alt='Logo'/>   
                            <div className='peoplebanerslide-item-name'>
                            Ibraheem <br/>
                            Saheel
                            </div>  
                        </div>
                    </div>
                </div>
                <div className='peoplebanersliderslide-item'>
                    <div className='peoplebaner-slider-wrper'> 
                        <div className='peoplebanerslide-item'>
                        <img loading="lazy" src={Peopleimg.peopleBanerimgAmna} alt='Logo'/>    
                            <div className='peoplebanerslide-item-name'>
                                Amna
                            </div>  
                        </div>
                    </div>
                </div>
                <div className='peoplebanersliderslide-item'>
                    <div className='peoplebaner-slider-wrper'> 
                        <div className='peoplebanerslide-item'>
                        <img loading="lazy" src={Peopleimg.peopleBanerimgHadi} alt='Logo'/>  
                            <div className='peoplebanerslide-item-name'>
                                Hadi
                            </div>  
                        </div>
                    </div>
                </div>
                <div className='peoplebanersliderslide-item'>
                    <div className='peoplebaner-slider-wrper'> 
                        <div className='peoplebanerslide-item'>
                        <img loading="lazy" src={Peopleimg.peopleBanerimgNizwa} alt='Logo'/>    
                            <div className='peoplebanerslide-item-name'>
                                 Nizwa
                            </div>  
                        </div>
                    </div>
                </div>
                <div className='peoplebanersliderslide-item'>
                    <div className='peoplebaner-slider-wrper'> 
                        <div className='peoplebanerslide-item'>
                        <img loading="lazy" src={Peopleimg.peopleBanerimgRizwan} alt='Logo'/>  
                            <div className='peoplebanerslide-item-name haris'>
                            Rizwan
                            </div>  
                        </div>
                    </div>
                </div>
                <div className='peoplebanersliderslide-item'>
                    <div className='peoplebaner-slider-wrper'> 
                        <div className='peoplebanerslide-item'>
                        <img loading="lazy" src={Peopleimg.peopleBanerimgNabhan} alt='Logo'/>   
                            <div className='peoplebanerslide-item-name'>
                            Nabhan
                            </div>  
        
                        </div>
                    </div>
                </div>

            </Slider>
        </div>
    </div>
  )
}
