import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import founder from '../../../assets/Assets/founder.png';
import slidetwo from '../../../assets/Assets/mobslide1.webp';
import slidethree from '../../../assets/Assets/mobslide2.webp';
import n from '../../../assets/Assets/popupmob.webp'
import mob from '../../../assets/Assets/mobgrid.webp';
import modalimage from '../../../assets/Assets/popupweb.png';
import { Modal, Button } from 'react-bootstrap';
import modalimagemobile from '../../../assets/Assets/popupmob.webp';

const Hero = () => {
  const [mouseOverProposal, setMouseOverProposal] = useState(false);
  const [mouseOverConsult, setMouseOverConsult] = useState(false);
  const [currentImage, setCurrentImage] = useState(0);
  // const [showModal, setShowModal] = useState(false);

  const images = [founder, slidetwo, slidethree];

  const [mouseOver, setMouseOver] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const [result, setResult] = React.useState("");

  const onSubmit = async (event) => {
    event.preventDefault();
    setResult("Sending....");
    const formData = new FormData(event.target);

    formData.append("access_key", "f94f2cd3-c0a3-494b-a2d4-ca767a1fd557");

    const response = await fetch("https://api.web3forms.com/submit", {
      method: "POST",
      body: formData
    });

    const data = await response.json();

    if (data.success) {
      setResult("Form Submitted Successfully");
      event.target.reset();
    } else {
      console.log("Error", data);
      setResult(data.message);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImage((prevIndex) => (prevIndex + 1) % images.length);
    }, 5000);

    return () => clearInterval(interval);
  }, [images.length]);

  return (
    <div className="award-winning" id="homeee">
      <div className="md:w-[50%] w-[100%]">
        <h1 className="mb-0 heading-text-hero abra-font fw">
          An <span className="primary-color fw abra-font">Award-winning</span>{" "}
          Digital{" "}
          <span className="block-text abra-font fw">
            {" "}
            Marketing Agency in Kerala{" "}
          </span>
        </h1>
        <p className="mb-0 sub-text-hero">
          A digital marketing company that’s on the course of powering up brands{" "}
          with the power of marketing, design and tech.{" "}
        </p>
        <div className="button-dv">
          <Link
            onMouseOver={() => setMouseOverProposal(true)}
            onMouseLeave={() => setMouseOverProposal(false)}
            className="btn proposal-btn primary-bg abra-font"
            onClick={() => setShowModal(true)}
          >
            GET A PROPOSAL
            {mouseOverProposal ? <ArrowForwardIcon /> : <ArrowOutwardIcon />}
          </Link>

          <Link
            onMouseOver={() => setMouseOverConsult(true)}
            onMouseLeave={() => setMouseOverConsult(false)}
            className="btn proposal-btn abra-font consult-bttn"
            to="https://wa.me/+918075040330"
          >
            Consult With Us
            {mouseOverConsult ? <ArrowForwardIcon /> : <ArrowOutwardIcon />}
          </Link>
        </div>
        <div className="count-dv mobile-none">
          <div className="w-[25%] border-lefttt">
            <h4 className="mb-0 number-text abra-font">600M+</h4>
            <p className="mb-0 number-text-sub abra-font  md:text-md text-xs">
              Revenue <span className="block-text abra-font">Generated</span>
            </p>
          </div>

          <div className="w-[25%] left-space border-lefttt">
            <h4 className="mb-0 number-text abra-font">150+</h4>
            <p className="mb-0 number-text-sub abra-font  md:text-md text-xs">
              Clients{" "}
            </p>
          </div>

          <div className="w-[25%] left-space border-lefttt">
            <h4 className="mb-0 number-text abra-font">1000+</h4>
            <p className="mb-0 number-text-sub abra-font  md:text-md text-xs">
              Projects
            </p>
          </div>

          <div className="w-[25%] left-space md:border-r-2 borderno md:border-r-white">
            <h4 className="mb-0 number-text abra-font">05</h4>
            <p className="mb-0 number-text-sub abra-font  md:text-md text-xs">
              National-level{" "}
              <span className="block-text abra-font">Awards</span>{" "}
            </p>
          </div>
        </div>
      </div>
      <div className="ceo-image">
        <img src={images[currentImage]} className="founder-img mobileno fade-image" alt="" />
        <img src={mob} className="founder-img mobileyes mobile-image-hero" alt="" />
      </div>
      <div className="count-dv mobile-block">
        <div className="mdain">
          <div className="w-[50%] md:border-r-2 md:border-r-white bbb">
            <h4 className="mb-0 number-text abra-font text-xs ">600M+</h4>
            <p className="mb-0 number-text-sub abra-font  md:text-md text-xs">
              Revenue <span className="block-text sblock abra-font">Generated</span>
            </p>
          </div>
          <div className="w-[50%] left-space md:border-r-2 md:border-r-white gg">
            <h4 className="mb-0 number-text abra-font">05</h4>
            <p className="mb-0 number-text-sub abra-font  md:text-md text-xs">
              National-level <span className="block-text abra-font">Awards</span>{" "}
            </p>
          </div>
        </div>

        <div className="mdain">
          <div className="w-[50%]  md:border-r-2 md:border-r-white bbb">
            <h4 className="mb-0 number-text abra-font">150+</h4>
            <p className="mb-0 number-text-sub abra-font  md:text-md text-xs">
              Clients{" "}
            </p>
          </div>

          <div className="w-[50%] left-space md:border-r-2 md:border-r-white">
            <h4 className="mb-0 number-text abra-font">1000+</h4>
            <p className="mb-0 number-text-sub abra-font  md:text-md text-xs">
              Projects
            </p>
          </div>
        </div>
      </div>
      <Modal className="modalmaster" size="lg" show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Body>
          <div className="modal-main-div">
            <div className="modal-half">
              <img src={modalimage} className="popimage" alt="" />
              <img src={n} className="popimagemobile" alt="" />
            </div>
            <div className="modal-half-p">
              <div className="modal-contentt">
                <i class="ri-close-circle-line closeicon" onClick={() => setShowModal(false)}></i>
                <h3 className="callback abra-font">We'll Call You Back</h3>
                <p className="popup-subtext web-pop abra-font">Our sales team will contact you soon to gather the  details needed to prepare your proposal.</p>
                <div className="modal-form">
                  <form onSubmit={onSubmit}>
                    <div className="form-group form-half-popup">
                      <label for="exampleInputEmail1">Full Name</label>
                      <input type="text" name="Name" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="" />
                    </div>
                    <div className="form-group form-half-popup">
                      <label for="exampleInputEmail1">Company*</label>
                      <input type="text" name="Company" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="" />
                    </div>
                    <div className="form-group form-half-popup">
                      <label for="exampleInputEmail1">Phone*</label>
                      <input type="text" name="Phone" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="" />
                    </div>
                    <div className="form-group form-half-popup">
                      <label for="exampleInputEmail1">Company email*</label>
                      <input type="email" name="mail" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="" />
                    </div>
                    <div class="form-group form-textarea">
                      <label for="exampleFormControlTextarea">Message*</label>
                      <textarea class="form-control mt-2" id="exampleFormControlTextarea1" rows="4"></textarea>
                    </div>
                    <button className="submit-button-form btn abra-font" type="submit">Submit Now</button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Hero;