import React from 'react';
import "./seocontent.css";
import { Container } from 'react-bootstrap';
import { useState } from 'react';

function Seocontent() {

  const [showFullContent, setShowFullContent] = useState(false);

  const handleReadMoreClick = () => {
    setShowFullContent(!showFullContent);
  };

  return (
    <div className='seocontent'>
      <Container>
        <div className='headcontent'>
          <h2 className='hd'>A Leading Revenue-focused Digital Marketing 
            Agency in Kerala
          </h2>
          <p className='txt'>We understand that getting likes and shares is great, but at Haris&Co, our focus is on driving real results for your business – increased revenue.</p>

          <p className='txt'>We achieve this by offering all the comprehensive digital marketing services you'd expect from a leading digital marketing company in Kerala but with a key difference:  a laser focus on maximising your return on investment (ROI).</p>

          <p className='txt'>To date, we have generated more than ₹600 Million for our clients including top brands such as Walkaroo, Skin Bae, BOSQ, Afyun Plywood, Femisafe, Million Dots, Algate, Visa Guy, Francis Alukkas and many more.</p>
        </div>
        <div className='headcontent'>
          <h2 className='twohd'>Why Us As Your Digital Marketing Company
          </h2>

          <ul>
            <li>
              A Compelling Portfolio: Our proven track record speaks for itself. We've helped businesses of all sizes and industries achieve significant growth through our data-driven marketing strategies. Check out our portfolio to see real-world examples of our success.
            </li>
            <li>
              Experienced Team: Our team is comprised of passionate and experienced digital marketing experts. We stay ahead of the curve with the latest trends and technologies to ensure your campaigns are cutting-edge and effective.
            </li>

            {showFullContent ? (
              <>
                <li>
                  ROI-focused Approach: As a digital marketing company in Kerala, we believe in transparency and accountability. That's why we focus on measurable results that directly impact your bottom line. We'll work closely with you to define your goals and develop a customised strategy that delivers a strong ROI.
                </li>
                <li>
                  Client-Centric: We take the time to understand your unique business needs and target audience. This ensures our campaigns are tailored to resonate with your ideal customers and achieve your specific goals.
                </li>
                <li>
                  Data-Driven Decisions: We are not fans of guesswork. Every decision we make is backed by data and analytics, allowing us to constantly optimise your campaigns for maximum impact.
                </li>
                <li>
                  Long-Term Partnerships: We believe in building long-term partnerships with our clients. We're here to help you achieve sustainable growth, not just a quick fix.
                </li>
								<p className='txt'>So, when you choose Haris&Co as your digital marketing agency in Kerala, you're gaining a trusted partner dedicated to driving real revenue for your business. Sounds awesome, right?</p>
								<div className='headcontent  headcontent-sub'>
          <h2 className='threehd'>Bagging Awards On the Way
          </h2>
          <p className='txt'>Haris&co. isn't just another digital marketing agency in Kerala. We put our heart into everything we do, be it SEO activities, Social Media campaigns or Performance Marketing campaigns. Over the years, we have delivered a shedload of projects to our clients and we have won awards for many of our works. Here is the list of awards we have won thus far:
          </p>
          <ul>
            <li>
              A Compelling Portfolio: Our proven track record speaks for itself. We've helped businesses of all sizes and industries achieve significant growth through our data-driven marketing strategies. Check out our portfolio to see real-world examples of our success.
            </li>
            <li>
              Experienced Team: Our team is comprised of passionate and experienced digital marketing experts. We stay ahead of the curve with the latest trends and technologies to ensure your campaigns are cutting-edge and effective.
            </li>
            <li>
              ROI-focused Approach: As a digital marketing company in Kerala, we believe in transparency and accountability. That's why we focus on measurable results that directly impact your bottom line. We'll work closely with you to define your goals and develop a customised strategy that delivers a strong ROI.
            </li>
            <li>
              Client-Centric: We take the time to understand your unique business needs and target audience. This ensures our campaigns are tailored to resonate with your ideal customers and achieve your specific goals.
            </li>
            <li>
              Data-Driven Decisions: We are not fans of guesswork. Every decision we make is backed by data and analytics, allowing us to constantly optimise your campaigns for maximum impact.
            </li>
            <li>
              Long-Term Partnerships: We believe in building long-term partnerships with our clients. We're here to help you achieve sustainable growth, not just a quick fix.
            </li>
          </ul>
        </div>
						  </>
            ) : (
              <button onClick={handleReadMoreClick} className='readmore-btn'>Read More</button>
            )}
								</ul>
         

        </div>
       
      </Container>
    </div>
  )
}

export default Seocontent;