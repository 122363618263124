import React from 'react'
import square from '../.././assets/slide.png'
import Slider from "react-slick";
const SocialMediaCreatives = () => {
	var settings = {
    dots: false,
    infinite: true,
    speed: 500,
		autoplay:true,
    slidesToShow: 3,
		arrows:false,
    slidesToScroll: 1,
  };
	return (
		<div className='container-main'>
			<div className="row">
				<div className="col-lg-12">
					<div className="heading-creatives-div">
						<h2 className='creatives-title abra-font'>Below are a few sample creatives</h2>
						<p className='sub-title-creartives abra-font'>You can explore the full set on instagram</p>
					</div>
				</div>
				<div className="col-lg-12">
					<div className="creative-sliders">
					<Slider {...settings}>
						<div className="mobile-logo-slider">
							<img src={square} className="creative-img" alt="" />
						</div>
						<div className="mobile-logo-slider">
							<img src={square} className="creative-img" alt="" />
						</div>
						<div className="mobile-logo-slider">
							<img src={square} className="creative-img" alt="" />
						</div>
						<div className="mobile-logo-slider">
							<img src={square} className="creative-img" alt="" />
						</div>
						<div className="mobile-logo-slider">
							<img src={square} className="creative-img" alt="" />
						</div>
						<div className="mobile-logo-slider">
							<img src={square} className="creative-img" alt="" />
						</div>
						<div className="mobile-logo-slider">
							<img src={square} className="creative-img" alt="" />
						</div>
						<div className="mobile-logo-slider">
							<img src={square} className="creative-img" alt="" />
						</div>
					</Slider>
					</div>
				</div>
			</div>
		</div>
	)
}
export default SocialMediaCreatives;