import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import Slider from "react-slick";
import team from "../../../assets/Assets/seo team.webp";
import social from '../../../assets/Assets/socialmediateam.webp'
import perfomance from '../../../assets/Assets/performancemarketingteam.webp'
import web from '../../../assets/Assets/webteam.webp'
import branding from '../../../assets/Assets/brandingteam.webp'


const Mobileservice = () => {

	
	var settings = {
    dots: true,
    infinite: true,
    speed: 500,
		autoplay:true,
    slidesToShow: 1,
		arrows:false,
    slidesToScroll: 1,
  };

	const [mouseOver, setMouseOver] = useState(false);

	const [currentImage, setCurrentImage] = useState(0); // State to track current image index

	const images = ["../../../assets/Assets/founder.svg"];

	useEffect(() => {
		// Function to change image every 3 seconds
		const interval = setInterval(() => {
			setCurrentImage((prevIndex) => (prevIndex + 1) % images.length);
		}, 5000); // Change image every 3000 milliseconds (3 seconds)

		return () => clearInterval(interval); // Clean up interval on component unmount
	}, [images.length]); // Dependency array to ensure interval is set correctly


	return (
		<div className='container-main'>
			<div className="mobile-service-section">
				<h2 className='abra-font service-mobile-text'>What We Have for You</h2>


				<Slider {...settings}>
					<div className="mobile-service-slider">
							<img src={team} className="mobileservice-img" alt="" />	
							<h3 className="abra-font service-name-mobile">SEO</h3>	
							<p className="servuice-sub-mobile">To appear on top of Google search results and get more website visitors, leads and revenue</p>
							<Link
						onMouseOver={() => setMouseOver(true)}
						onMouseLeave={() => setMouseOver(false)}
						className="btn vvv-btn primary-bg abra-font"
						to="/about"
					>
						View More
						{mouseOver ? (
							<ArrowForwardIcon />
						) : (
							<ArrowOutwardIcon />
						)}
					</Link>
					</div>
					<div className="mobile-service-slider">
							<img src={perfomance} className="mobileservice-img" alt="" />	
							<h3 className="abra-font service-name-mobile">Performance Marketing</h3>	
							<p className="servuice-sub-mobile">To gain measurable results that drive conversions and maximise your ROI with Digital Ads. 
							</p>
							<Link
						onMouseOver={() => setMouseOver(true)}
						onMouseLeave={() => setMouseOver(false)}
						className="btn vvv-btn primary-bg abra-font"
						to="/about"
					>
						View More
						{mouseOver ? (
							<ArrowForwardIcon />
						) : (
							<ArrowOutwardIcon />
						)}
					</Link>
					</div>
					<div className="mobile-service-slider">
							<img src={social} className="mobileservice-img" alt="" />	
							<h3 className="abra-font service-name-mobile">Social Media Marketing
							</h3>	
							<p className="servuice-sub-mobile">To build brand awareness, retain customers, and generate more leads on Social Media.
							</p>
							<Link
						onMouseOver={() => setMouseOver(true)}
						onMouseLeave={() => setMouseOver(false)}
						className="btn vvv-btn primary-bg abra-font"
						to="/about"
					>
						View More
						{mouseOver ? (
							<ArrowForwardIcon />
						) : (
							<ArrowOutwardIcon />
						)}
					</Link>
					</div>
					<div className="mobile-service-slider">
							<img src={web} className="mobileservice-img" alt="" />	
							<h3 className="abra-font service-name-mobile">Web Development
							</h3>	
							<p className="servuice-sub-mobile">For a website that combines engaging UI, scroll-stopping content and user-friendliness.</p>
							<Link
						onMouseOver={() => setMouseOver(true)}
						onMouseLeave={() => setMouseOver(false)}
						className="btn vvv-btn primary-bg abra-font"
						to="/about"
					>
						View More
						{mouseOver ? (
							<ArrowForwardIcon />
						) : (
							<ArrowOutwardIcon />
						)}
					</Link>
					</div>
					<div className="mobile-service-slider">
							<img src={branding} className="mobileservice-img" alt="" />	
							<h3 className="abra-font service-name-mobile">Branding
							</h3>	
							<p className="servuice-sub-mobile">To help you change the face of your brand, set a new standard and stand out from the crowd. 
							</p>
							<Link
						onMouseOver={() => setMouseOver(true)}
						onMouseLeave={() => setMouseOver(false)}
						className="btn vvv-btn primary-bg abra-font"
						to="/about"
					>
						View More
						{mouseOver ? (
							<ArrowForwardIcon />
						) : (
							<ArrowOutwardIcon />
						)}
					</Link>
					</div>
			
				</Slider>




			</div>
		</div>
	)
}

export default Mobileservice