import React, { useState, useEffect } from 'react';
import './navbar.css';
import { Container } from 'react-bootstrap';
import NavLogo from '../../assets/navlogo.png';
import NavLogoDark from '../../assets/images/logo-dark.png';
import { Link, Outlet } from 'react-router-dom';

export default function NavBar() {
  const [isActive, setIsActive] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  const toggleMenu = () => {
    setIsActive(!isActive);
    document.body.classList.toggle('menu-open');
    // window.location.reload();
  };


  useEffect(()=>{
    toggleMenu();
    closeMenu();
  },[])
  
  const closeMenu = () => {
    setIsActive(false);
    document.body.classList.remove('menu-open');
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      if (scrollTop > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
    
  });

  return (
    <div className={`navbar-main ${isScrolled ? 'scrolled' : ''} ${isScrolled ? '' : 'top'}`}>
      <Container>
        <header>
        <a href='/' title='Logo' className='logo'>
            <img loading="lazy" src={NavLogo} alt='Logo' className='hidelogo-lightbg'/>   
            <img loading="lazy" src={NavLogoDark} alt='Logo' className='hidelogo-darkbg'/>     
        </a>
          <a className={`menu-btn ${isActive ? 'active' : ''}`} onClick={toggleMenu}>
            <span className="bar"></span>
            <span className="bar"></span>
            <span className="bar"></span>
          </a>
        </header>
        <div className={`header__nav ${isActive ? 'active' : ''}`}>
          <nav className="nav">
            <Container>
            <ul className="nav__list">
              <li className="nav__list_item">
                  <Link className='nav__link' to='/'>Home</Link>
              </li>
              <li className="nav__list_item">
                 <Link className='nav__link' to='/service'>Service</Link>
                  <div className=''></div>
              </li>
              <li className="nav__list_item">
                 <Link className='nav__link' to='/blog'>Blog</Link>
              </li>
              <li className="nav__list_item">
                 <Link className='nav__link' to='/People'>People</Link>
              </li>
              <li className="nav__list_item">
                 <Link className='nav__link' to='/Contactpage'>Contact Us</Link>
              </li>
            </ul>

            </Container>
          </nav>
          <Outlet/>
        </div>
      </Container>
    </div>
  );
}
