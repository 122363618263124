import React, { useEffect, useState } from "react";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import { Link } from "react-router-dom";
const Consult = () => {

	const [mouseOver, setMouseOver] = useState(false);

	const [currentImage, setCurrentImage] = useState(0); // State to track current image index

	const images = ["./assets/founder.svg"];

	useEffect(() => {
		// Function to change image every 3 seconds
		const interval = setInterval(() => {
			setCurrentImage((prevIndex) => (prevIndex + 1) % images.length);
		}, 5000); // Change image every 3000 milliseconds (3 seconds)

		return () => clearInterval(interval); // Clean up interval on component unmount
	}, [images.length]); // Dependency array to ensure interval is set correctly


	return (
		<div className="col-lg-12">
			<div className="consult-section">
				<div className="consult-content">
					<h2 className="bebas-neue-regular letsdiscuss mb-0 text-white text-2xl md:text-6xl">
						Let's Discuss How We Can Grow{" "}
						<span className="block-text bebas-neue-regular">
							Your Business
						</span>{" "}
					</h2>
				</div>
				<div className="relative">
					<Link
						onMouseOver={() => setMouseOver(true)}
						onMouseLeave={() => setMouseOver(false)}
						className="btn consult-btn primary-bg abra-font"
						to="https://wa.me/+918075040330"
					>
								Consult Us
						{mouseOver ? (
							<ArrowForwardIcon />
						) : (
							<ArrowOutwardIcon />
						)}
					</Link>
				</div>
			</div>
		</div>
	);
};

export default Consult;
