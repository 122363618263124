import React from "react";
import Slider from "react-slick";
import one from "../../../assets/Assets/white-logo/Afyun w logo.png";
import two from "../../../assets/Assets/white-logo/Baby botton w logo.png";
import three from "../../../assets/Assets/white-logo/Berry-&-co w logo.png";
import four from "../../../assets/Assets/white-logo/Bosq w logo.png";
import five from "../../../assets/Assets/white-logo/cafeicemagic w logo.png";

import ten from "../../../assets/Assets/white-logo/selex w logo.png";
import eleven from "../../../assets/Assets/white-logo/Volkswagen white logo.png";
import twelve from "../../../assets/Assets/white-logo/WAlkaroo white logo.png";
import fourteen from "../../../assets/Assets/white-logo/amana w logo.png";
import fifteen from "../../../assets/Assets/white-logo/audi white logo.png";
import sixteen from "../../../assets/Assets/white-logo/bliss w logo.png";
import seventeen from "../../../assets/Assets/white-logo/bluetyga w logo.png";
import eighteen from "../../../assets/Assets/white-logo/cafeicemagic w logo.png";
import nineteen from "../../../assets/Assets/white-logo/care n cure w logo.png";
import twenty from "../../../assets/Assets/white-logo/dopa w logo.png";
import twentyone from "../../../assets/Assets/white-logo/english bus w logo.png";
import twentytwo from "../../../assets/Assets/white-logo/francis-alukkas w logo.png";
import twentythree from "../../../assets/Assets/white-logo/geojith w logo.png";
import twentyfour from "../../../assets/Assets/white-logo/infinitycare w logo.png";
import twentyfive from "../../../assets/Assets/white-logo/kairalitmt white logo.png";
import twentysix from "../../../assets/Assets/white-logo/katara w logo.png";
import thirtyeight from "../../../assets/Assets/white-logo/popees w logo.png";
import twentyeight from "../../../assets/Assets/white-logo/milliondots w logo.png";
import fortyeight from "../../../assets/Assets/white-logo/visaguy w logo.png";


const Mobileslider = () => {


	var settings = {
    dots: false,
    infinite: true,
    speed: 500,
		autoplay:true,
    slidesToShow: 3,
		arrows:false,
    slidesToScroll: 1,
  };
	return (
		<div className="container-main">
		<div className="slider-mobile slidertop">
		<Slider {...settings}>
		<div className="mobile-logo-slider">
			<img src={fifteen} className="logo-main" alt="" />
		</div>
		<div className="mobile-logo-slider">
			<img src={eleven} className="logo-main" alt="" />
		</div>
		<div className="mobile-logo-slider">
			<img src={twentyfive} className="logo-main" alt="" />
		</div>
		<div className="mobile-logo-slider">
			<img src={twelve} className="logo-main" alt="" />
		</div>
		<div className="mobile-logo-slider">
			<img src={one} className="logo-main" alt="" />
		</div>
		<div className="mobile-logo-slider">
			<img src={four} className="logo-main" alt="" />
		</div>
		<div className="mobile-logo-slider">
			<img src={thirtyeight} className="logo-main" alt="" />
		</div>
		<div className="mobile-logo-slider">
			<img src={twentyeight} className="logo-main" alt="" />
		</div>
		<div className="mobile-logo-slider">
			<img src={twentytwo} className="logo-main" alt="" />
		</div>
		<div className="mobile-logo-slider">
			<img src={fortyeight} className="logo-main" alt="" />
		</div>
		<div className="mobile-logo-slider">
			<img src={fourteen} className="logo-main" alt="" />
		</div>
		<div className="mobile-logo-slider">
			<img src={nineteen} className="logo-main" alt="" />
		</div>
		<div className="mobile-logo-slider">
			<img src={two} className="logo-main" alt="" />
		</div>
		<div className="mobile-logo-slider">
			<img src={three} className="logo-main" alt="" />
		</div>
		<div className="mobile-logo-slider">
			<img src={five} className="logo-main" alt="" />
		</div>
		<div className="mobile-logo-slider">
			<img src={sixteen} className="logo-main" alt="" />
		</div>
		<div className="mobile-logo-slider">
			<img src={seventeen} className="logo-main" alt="" />
		</div>
		<div className="mobile-logo-slider">
			<img src={eighteen} className="logo-main" alt="" />
		</div>
		<div className="mobile-logo-slider">
			<img src={nineteen} className="logo-main" alt="" />
		</div>
		<div className="mobile-logo-slider">
			<img src={twenty} className="logo-main" alt="" />
		</div>
		<div className="mobile-logo-slider">
			<img src={twentyone} className="logo-main" alt="" />
		</div>
		<div className="mobile-logo-slider">
			<img src={twentytwo} className="logo-main" alt="" />
		</div>
		<div className="mobile-logo-slider">
			<img src={twentythree} className="logo-main" alt="" />
		</div>
		<div className="mobile-logo-slider">
			<img src={twentyfour} className="logo-main" alt="" />
		</div>
		<div className="mobile-logo-slider">
			<img src={twentyfive} className="logo-main" alt="" />
		</div>
		<div className="mobile-logo-slider">
			<img src={twentysix} className="logo-main" alt="" />
		</div>
	</Slider>
		</div>
		</div>
	)
}
export default Mobileslider;