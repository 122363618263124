const casestudyData = {
    "SEO": [
      {
        title: "Website Visits Shot From 1K to a Massive 1.5 Lakhs",
        description:
          "Read in detail the challenges Kairali TMT faced, what we did to address them and the results they achieved.",
        image: "Seo/2.webp",
      },
      {
        title: "Generated 500+ Sales-qualified Leads in Just 6 Months",
        description:
          "Explore how we helped BOSQ increase organic lead generation and website visits.",
        image: "Seo/1.webp",
      },
      {
        title:
          "Ranked a Skin Care Brand Above Giants Like Nykaa, Amazon, and Macaron",
        description:
          "Read in detail the strategy we implemented to make Skin Bae stand out on Google.",
        image: "Seo/3.webp",
      },
    ],
    SEO: [
      {
        title: "Website Visits Shot From 1K to a Massive 1.5 Lakhs",
        description:
          "Read in detail the challenges Kairali TMT faced, what we did to address them and the results they achieved.",
        image: "Seo/2.webp",
      },
      {
        title: "Generated 500+ Sales-qualified Leads in Just 6 Months",
        description:
          "Explore how we helped BOSQ increase organic lead generation and website visits.",
        image: "Seo/1.webp",
      },
      {
        title:
          "Ranked a Skin Care Brand Above Giants Like Nykaa, Amazon, and Macaron",
        description:
          "Read in detail the strategy we implemented to make Skin Bae stand out on Google.",
        image: "Seo/3.webp",
      },
    ],
    "Social Media": [
      {
        title: "Hit 41K Views for Reels Within 5 Days",
        description:
          "See how the inauguration campaign of Care n Cure drove brand awareness.",
        image: "Socialmedia/3.webp",
      },
      {
        title: "17,000 to 59,900 Followers in Just 60 Days",
        description:
          "Learn the way KiddieBus cracked the followers-winning formula with our strategy.",
        image: "Socialmedia/2.webp",
      },
      {
        title: "100,000 Impressions and 100,000 Engagements Within One Week",
        description:
          "Here is how Care n Cure found a way with us to skyrocket their impressions and engagements.",
        image: "Socialmedia/1.webp",
      },
    ],
    "PR & Communication": [
      {
        title: "1 Campaign - 1.8 Million Views and 157000+ Likes!",
        description:
          "This campaign's case study shows how enormous the results can be with influencer marketing.",
        image: "PRCommunication/1.webp",
      },
      {
        title: "Garnered 5.4M Views for JBL’s Vishu Campaign",
        description:
          "This case study about the Vishu campaign for JBL shows the power of influencer marketing.",
        image: "PRCommunication/2.webp",
      },
      {
        title: "Influencer Campaign Brought 364K+ Views for Reliance Trends",
        description:
          "Learn how we ideated a perfect campaign for Reliance Trends and drove 1000s of views.",
        image: "PRCommunication/3.webp",
      },
    ],
    "Web Development": [
      {
        title: "Redesigned an E-commerce Store",
        description:
          "Take a look into Popees’ (a leading Kidswear brand) brand new website.",
        image: "Webdevelopment/3.webp",
      },
      {
        title: "A Complete Revamp",
        description: "We gave Nassguard's website a completely new look.",
        image: "Webdevelopment/1.webp",
      },
      {
        title: "Gave a New Look for Walkaroo’s Store",
        description:
          "See how the redesigned Ecommerce website we developed for Walkaroo brought them leads.",
        image: "Webdevelopment/2.webp",
      },
    ],
    "Creative Strategy": [
      {
        title: "Scaled Revenue by 110%+ in Just 4 Months",
        description:
          "Learn how we scaled Skin Bae - a Korean skincare brand, from 900,000 to 20,00,000.",
        image: "Creativestrategy/3.webp",
      },
      {
        title: "Fetched 147 Registrations for a Workshop in Just 14 Days",
        description:
          "This case study shows how Million Dots found their lead generation campaign successful.",
        image: "Creativestrategy/2.webp",
      },
      {
        title: "1 Video - 3+ ROAS & 380,000+ Impressions",
        description:
          "Learn how creativity paved the way for Blue Tyga to increase sales and brand awareness.",
        image: "Creativestrategy/1.webp",
      },
    ],
    Branding: [
      {
        title: "Automate My Biz",
        description:
          "Logo, Website UI, ID Card design, and Banners. We gave them all to create a face.",
        image: "Branding/1.webp",
      },
      {
        title: "Quarter Plate",
        description:
          "Witness what our branding team ideated and developed for this restaurant.",
        image: "Branding/2.webp",
      },
      {
        title: "Heal Bev",
        description:
          "Explore everything we developed from an elegant logo to an eye-catching package design for this beverage brand.",
        image: "Branding/3.webp",
      },
    ],
  };
  
  export default casestudyData;
  