import React, { useEffect, useState } from "react";
import servicelogoone from "../../assets/seo1 1.png";
import servicelogotwo from "../../assets/off-page-seo1 1.png";
import servicelogothree from "../../assets/technical-support1 1.png";
import servicelogofour from "../../assets/Branding (1) 2.png";
import servicelogofive from "../../assets/Assets/content-writing1 1.svg";
import servicelogof from "../../assets/cro.svg";
import team from "../../assets/on page.png";
import social from '../../assets/technical seo.png'
import perfomance from '../../assets/off page.png'
import web from '../../assets/ui ux.png'
import branding from '../../assets/content.png'
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import ss from '../../assets/crm.png'
import AccordionItem from "../custom/AccordionItem";
import CallReceivedIcon from "@mui/icons-material/CallReceived";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import { Link } from "react-router-dom";
import arrow from "../../assets/Assets/Vector (3).svg";
const WhatWeSocialMedia = () => {
  const [mouseOver, setMouseOver] = useState(false);

  const [currentImage, setCurrentImage] = useState(0); // State to track current image index

  const images = ["./assets/founder.svg"];

  useEffect(() => {
    // Function to change image every 3 seconds
    const interval = setInterval(() => {
      setCurrentImage((prevIndex) => (prevIndex + 1) % images.length);
    }, 5000); // Change image every 3000 milliseconds (3 seconds)

    return () => clearInterval(interval); // Clean up interval on component unmount
  }, [images.length]); // Dependency array to ensure interval is set correctly

  const [mouseHover, setMouseHover] = useState(false);

  const [accordionStates, setAccordionStates] = useState([
    true,
    false,
    false,
    false,
    false,
  ]);

  return (
    <div className="lg:top-[-300px] lg:z-10 container-main">
      <div className="services-section">
        <h2 className="service-heading-text mb-0 abra-font"> Our SEO Toolkit</h2>
        <div className="tab-div">
          <Tab.Container id="left-tabs-example" defaultActiveKey="first">
            <div className="sub-tab">
              <Nav variant="pills" className="main-block-main scf">
                <Nav.Item>
                  <Nav.Link eventKey="first">
                    <AccordionItem
                      state={accordionStates[0] === true}
                      onClick={() => {
                        if (accordionStates[0] === true)
                          setAccordionStates([
                            false,
                            false,
                            false,
                            false,
                            false,
                          ]);
                        else
                          setAccordionStates([
                            true,
                            false,
                            false,
                            false,
                            false,
                          ]);
                      }}
                      title={
                        <div className="flex items-center">
                          <img
                            src={servicelogoone}
                            className="service-logo"
                            alt=""
                          />
                          <h4 className=" mb-0 abra-font service-text">
													Social Media Strategy Development 
                          </h4>
                        </div>
                      }
                    >

                    </AccordionItem>
                  </Nav.Link>
                </Nav.Item>

                <Nav.Item>
                  <Nav.Link eventKey="second">
                    <AccordionItem
                      state={accordionStates[1] === true}
                      onClick={() => {
                        if (accordionStates[1] === true)
                          setAccordionStates([
                            false,
                            false,
                            false,
                            false,
                            false,
                          ]);
                        else
                          setAccordionStates([
                            false,
                            true,
                            false,
                            false,
                            false,
                          ]);
                      }}
                      title={
                        <div className="flex items-center">
                          <img
                            src={servicelogotwo}
                            className="service-logo-small"
                            alt=""
                          />
                          <h4 className=" mb-0 abra-font service-text">
													Content Creation                           </h4>
                        </div>
                      }
                    >

                    </AccordionItem>
                  </Nav.Link>
                </Nav.Item>

                <Nav.Item>
                  <Nav.Link eventKey="tree">
                    <AccordionItem
                      state={accordionStates[2] === true}
                      onClick={() => {
                        if (accordionStates[2] === true)
                          setAccordionStates([
                            false,
                            false,
                            false,
                            false,
                            false,
                          ]);
                        else
                          setAccordionStates([
                            false,
                            false,
                            true,
                            false,
                            false,
                          ]);
                      }}
                      title={
                        <div className="flex items-center">
                          <img
                            src={servicelogothree}
                            className="service-logo-small"
                            alt=""
                          />
                          <h4 className=" mb-0 abra-font service-text">
													Page & Community Management
                          </h4>
                        </div>
                      }
                    >

                    </AccordionItem>
                  </Nav.Link>
                </Nav.Item>

                <Nav.Item>
                  <Nav.Link eventKey="four">
                    <AccordionItem
                      state={accordionStates[3] === true}
                      onClick={() => {
                        if (accordionStates[3] === true)
                          setAccordionStates([
                            false,
                            false,
                            false,
                            false,
                            false,
                          ]);
                        else
                          setAccordionStates([
                            false,
                            false,
                            false,
                            true,
                            false,
                          ]);
                      }}
                      title={
                        <div className="flex items-center">
                          <img
                            src={servicelogofour}
                            className="service-logo-small"
                            alt=""
                          />
                          <h4 className=" mb-0 abra-font service-text">
													Analytics and Reporting
                          </h4>
                        </div>
                      }
                    >

                    </AccordionItem>
                  </Nav.Link>
                </Nav.Item>


                <Nav.Item>
                  <Nav.Link eventKey="six">
                    <AccordionItem
                      state={accordionStates[3] === true}
                      onClick={() => {
                        if (accordionStates[3] === true)
                          setAccordionStates([
                            false,
                            false,
                            false,
                            false,
                            false,
                          ]);
                        else
                          setAccordionStates([
                            false,
                            false,
                            false,
                            true,
                            false,
                          ]);
                      }}
                      title={
                        <div className="flex items-center">
                          <img
                            src={servicelogof}
                            className="service-logo-small"
                            alt=""
                          />
                          <h4 className=" mb-0 abra-font service-text">
													AI Powered Chat Automations
                          </h4>
                        </div>
                      }
                    >

                    </AccordionItem>
                  </Nav.Link>
                </Nav.Item>

                <Nav.Item>
                  <Nav.Link style={{ width: "100%" }} eventKey="five">
                    <AccordionItem
                      state={accordionStates[4] === true}
                      onClick={() => {
                        if (accordionStates[4] === true)
                          setAccordionStates([
                            false,
                            false,
                            false,
                            false,
                            false,
                          ]);
                        else
                          setAccordionStates([
                            false,
                            false,
                            false,
                            false,
                            true,
                          ]);
                      }}
                      title={
                        <div className="flex items-center">
                          <img
                            src={servicelogofive}
                            className="service-logo-small"
                            alt=""
                          />
                          <h4 className=" mb-0 abra-font service-text">
                          Advertising and Paid Social

                          </h4>
                        </div>
                      }
                    >

                    </AccordionItem>
                  </Nav.Link>
                </Nav.Item>

                {/* <Link
                  onMouseOver={() => setMouseOver(true)}
                  onMouseLeave={() => setMouseOver(false)}
                  className="btn learn-btn primary-bg abra-font ml-5"
                  to="/service"
                >
                  View More
                  {mouseOver ? <ArrowForwardIcon /> : <ArrowOutwardIcon />}
                </Link> */}
              </Nav>
            </div>
            <div className="sub-tab hidden md:block">
              <Tab.Content>
                <Tab.Pane eventKey="first" className="relative">
                  {mouseHover && (
                    <div className="absolute right-0 top-0 flex items-center justify-end">
                      <img src={arrow} alt="" />
                    </div>
                  )}
                  <img
                    onMouseEnter={(e) => setMouseHover(true)}
                    onMouseLeave={(e) => setMouseHover(false)}
                    src={team}
                    className="seo-team-png"
                    alt=""
                  />
                  <p className=" subtextwhatwedo">
                    This is about shortlisting the right keywords, optimising the URLs, correcting the title tags, and optimising other elements on the website to tidy up.
                  </p>
                </Tab.Pane>
                <Tab.Pane eventKey="second" className="relative">
                  {mouseHover && (
                    <div className="absolute right-0 top-0 flex items-center justify-end">
                      <img src={arrow} alt="" />
                    </div>
                  )}
                  <img
                    onMouseEnter={(e) => setMouseHover(true)}
                    onMouseLeave={(e) => setMouseHover(false)}
                    src={perfomance}
                    className="seo-team-png"
                    alt=""
                  />
                  <p className=" subtextwhatwedo">
                    This focuses on building your website's reputation online by posting blogs on PR sites, listing on directories, listing on business listing sites and more.
                  </p>
                </Tab.Pane>
                <Tab.Pane className="relative" eventKey="tree">
                  {mouseHover && (
                    <div className="absolute right-0 top-0 flex items-center justify-end">
                      <img src={arrow} alt="" />
                    </div>
                  )}
                  <img
                    onMouseEnter={(e) => setMouseHover(true)}
                    onMouseLeave={(e) => setMouseHover(false)}
                    src={social}
                    className="seo-team-png"
                    alt=""
                  />
                  <p className=" subtextwhatwedo">
                    This involves auditing and sorting aspects of the website such as the load speed, security, server performance, website architecture and more to ensure optimal performance.
                  </p>
                </Tab.Pane>
                <Tab.Pane className="relative" eventKey="four">
                  {mouseHover && (
                    <div className="absolute right-0 top-0 flex items-center justify-end">
                      <img src={arrow} alt="" />
                    </div>
                  )}
                  <img onMouseEnter={(e) => setMouseHover(true)} onMouseLeave={(e) => setMouseHover(false)} src={web} className="seo-team-png" alt="" />
                  <p className="subtextwhatwedo">  UI includes designing the visual design like fonts, colours and layout to make your website attractive. UX design involves making your website easy to navigate. </p>
                </Tab.Pane>
                <Tab.Pane className="relative" eventKey="six">
                  {mouseHover && (
                    <div className="absolute right-0 top-0 flex items-center justify-end">
                      <img src={arrow} alt="" />
                    </div>
                  )}
                  <img
                    onMouseEnter={(e) => setMouseHover(true)}
                    onMouseLeave={(e) => setMouseHover(false)}
                    src={ss}
                    className="seo-team-png"
                    alt=""
                  />
                  <p className=" subtextwhatwedo">
                  This is about analysing the UI/UX, identifying the gaps and optimising the elements to increase the chance of converting visitors to customers.
                  </p>
                </Tab.Pane>
                <Tab.Pane className="relative" eventKey="five">
                  {mouseHover && (
                    <div className="absolute right-0 top-0 flex items-center justify-end">
                      <img src={arrow} alt="" />
                    </div>
                  )}
                  <img
                    onMouseEnter={(e) => setMouseHover(true)}
                    onMouseLeave={(e) => setMouseHover(false)}
                    src={branding}
                    className="seo-team-png"
                    alt=""
                  />
                  <p className=" subtextwhatwedo">
                    This is about creating awesome content that informs, entertains, engages and ultimately converts your visitors to customers.

                  </p>
                </Tab.Pane>
              </Tab.Content>
            </div>
          </Tab.Container>
        </div>
      </div>
    </div>
  );
};

export default WhatWeSocialMedia;