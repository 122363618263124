import React from 'react';
import './seocontent.css';
import { Container } from 'react-bootstrap';
import { useState } from 'react';

function Seocontent() {
  const [showFullContent, setShowFullContent] = useState(false);

  const handleReadMoreClick = () => {
    setShowFullContent(!showFullContent);
  };

  return (
    <div className='seocontentmain'>
      <Container>
        <div className='headcontent'>
          <h2 className='hd'>An SEO Company in Kerala That Brings You Real Results</h2>
          <p className='txt'>Forget generic strategies and cookie-cutter campaigns. As a well-established SEO company in Kerala, we craft individualized plans that target your specific audience and industry. We don't just boost website traffic (though we excel at that too); we focus on attracting qualified visitors who are ready to convert into paying customers.</p>
          <p className='txt'>Imagine your website ranking high on search results for keywords that matter to your business. That's the power of our data-driven SEO approach. We combine in-depth keyword research, strategic on-page optimization, and effective link building to get your website noticed by the right people.</p>
          <p className='txt'>Stop waiting for customers to find you. Get our SEO service in Kerala and get your website ranking high and your business booming.</p>
        </div>
        <div className='headcontent'>
          <h2 className='twohd'>Why You Should Invest in Our SEO</h2>
          <ul>
            <li>
              Reach more interested customers: We bring people who are already searching for what you offer. They're high-quality leads compared to those seeing a random ad.
            </li>
            <li>
              Boost website traffic: As an SEO company in Kerala, we help your website rank higher in search results, meaning more people will find you naturally.
            </li>
            {showFullContent ? (
							<>
              <div>
                <li>
                  Improve brand awareness: Higher rankings and organic traffic lead to more brand recognition and establish you as an authority.
                </li>
                <li>
                  Get a better return on investment: Our SEO is a cost-effective way to generate leads and sales since our strategy ultimately focuses on bringing you better ROI.
                </li>
                <li>
                  Long-term benefits: Unlike ads, SEO results are long-lasting. Once you rank well, you'll continue to see traffic for months or even years and we will make sure by tweaking the strategies if needed.
                </li>
              </div>
							        <div className='headcontent headcontent-sub'>
											<h2 className='threehd'>The SEO Company in Kerala With a Professional Process</h2>
											<ul>
												<li>
													Brand Understanding: Our SEO team start by getting to know your brand, its goals, target audience, and unique selling proposition (USP).
												</li>
												<li>
													Industry & Competitor Research: We study your industry, analyze your competitors' SEO strategies, and identify potential opportunities for your website.
												</li>
												<li>
													Website Audit: We conduct a comprehensive technical audit of your website to identify any SEO roadblocks and areas for improvement.
												</li>
												<li>
													Keyword Research: We research relevant keywords with high search volume and low competition to attract qualified traffic.
												</li>
												<li>
													Tracking the Results: We closely monitor website traffic, keyword rankings, and other key metrics to measure progress.
												</li>
												<li>
													Reporting to the Client: We provide regular reports with clear visualizations, keeping you informed of the campaign's performance.
												</li>
												<li>
													Tweaking the Plan: SEO is an ongoing process. We continuously analyze data and adjust the plan as needed to optimize for search engine algorithms.
												</li>
												<li>
													Maintaining the Results: We focus on long-term SEO strategies to ensure your website maintains its high ranking and organic visibility.
												</li>
											</ul>
										</div>
							</>

            ) : (
              <button onClick={handleReadMoreClick} className='readmore-btn'>Read More</button>
            )}
          </ul>
        </div>

      </Container>
    </div>
  );
}
export default Seocontent;