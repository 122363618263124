import React, { useEffect, useState } from "react";
import google from "../../../assets/Group 40.png";
import user from "../../../assets/Assets/Ellipse 7.svg";

const reviews = [

  {
	name: "This is great ! ",
	text: "“The professionalism, attention to detail, and the quality of service you have demonstrated have been instrumental in our success. It is a pleasure to work with this team that consistently goes above and beyond expectations.Thank you the exceptional work. We look forward to continuing our partnership and achieving even better results.””",
	userName: "Kevin Mohan",
	userDesc: "Marketing Head of Katara limousine",
},
{
	name: "Hi team,",
	text: "“Happy to let you know that we have closed a new client via SEO. Good work and let’s keep improving this and get more leads. ”",
	userName: "Ashik Nasir Khan",
	userDesc: "Marketing Head of Mr Alfred",
},


  {
    type: "text", // This indicates a text review
    name: "Hi Team",
    text: "Recently, most of them were quality leads. This month, we received lot of leads. The sales team was appreciating. Few were really good orders. ",
    userName: "Mohammed Sahil",
    userDesc: "Marketing Head of Afyun Plywood",
  },
  // ... other reviews
];

const Review = ({ review, isVisible }) => {
  
  if (review.type === "video") {
    return (
      <div
        className={`w-full max-w-[500px] rounded-2xl bg-black transition-opacity duration-1000 ${
          isVisible ? "opacity-100" : "opacity-0"
        } absolute right-0`}
      >
        <iframe
          src={review.url}
          width="100%"
          height="250px"
          frameBorder="0"
          allow=""
          title={review.name}
        />
      </div>
    );
  } else {
    return (
      <div
        className={`w-full max-w-[500px] rounded-2xl bg-black transition-opacity duration-1000 ${
          isVisible ? "opacity-100" : "opacity-0"
        } absolute right-0`}
      >
        <div className="review-main">
          <div className="review-box   shadow-md">
            <h6 className="text-white reviewer-name mb-0">{review.name}</h6>
            <p className="review-text mb-0">{review.text}</p>
            <div className="rev-profile  flex items-center">
              <img
                src={user}
                className="user-img w-12 h-12 rounded-full mr-2"
                alt=""
              />
              <div className="user-name-dv">
                <h6 className="mb-0 user-name">{review.userName}</h6>
                <p className="mb-0 user-desc text-gray-500">{review.userDesc}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

const Clients = () => {
  const [visibleReview, setVisibleReview] = useState(0);
  const [isDelayed, setIsDelayed] = useState(false);

  useEffect(() => {
    const changeReview = () => {
      setIsDelayed(true);
      setTimeout(() => {
        setVisibleReview((prev) => (prev + 1) % reviews.length);
        setIsDelayed(false);
      }, 500); // Delay before fade-in
    };

    const interval = setInterval(changeReview, 6000); // 2 seconds interval (1 second delay + 1 second display time)

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="clients-reviews py-8">
      <div className="container-main mx-auto">
        <div className="flex flex-col md:flex-row items-center justify-between">
          <div className="w-full md:w-1/2 mb-8 md:mb-0">
            <div className="review-main">
              <h2 className="clients-heading mb-2 abra-font">Client Reviews</h2>
              <p className="mb-4 clients-sub">
                Here is what our clients have to say about our service.
              </p>
              <img src={google} className="clients-logo" alt="" />
            </div>
          </div>

          <div className="relative w-full md:w-1/2 h-[250px]">
            {reviews.map((review, index) => (
              <Review
                key={index}
                review={review}
                isVisible={index === visibleReview && !isDelayed}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Clients;
