import React from 'react'
import NavBar from '../NavBar/NavBar'
import './blog.css'
import { Col, Container, Row } from 'react-bootstrap'
import CaseStudyimg from '../../assets/imagIndex'
import Footer from '../Footer/Footer'

export default function Blog() {
  return (
    <div>
        <NavBar/>
        <div className='casestudy-wpprer'>
            <Container>
              <div className='casestudy-maincontent'>
                  <div className='casestudy-maincontent-hd'>
                  Case Study
                  </div>
                  <div className='casestudy-maincontent-txt '>
                  Ideas are always plenty, but only some take flight and turn dreams into reality. It's even better when these
                  ideas make an impact that only encourages us to launch more amazing campaigns for leading clients! Here
                  s a snapshot of the #KinnectIMPACT we make everyday.
                  </div>
              </div>
              <Row>
                     <Col md={4} className='clo'>
                        <div className='casestudy-box'>
                            <div className='casestudy-img'>
                              <img loading="lazy" src={CaseStudyimg.casestudyimage1} alt='Logo'/>
                            </div>
                            <div className='casestudy-content'>
                                <div className='casestudy-hd'>
                                    Lorem ipsum dolor sit amet
                                </div>
                                <div className='casestudy-txt'>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec non tellus quis quam molestie vulputate.
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col md={4} className='clo'>
                        <div className='casestudy-box'>
                            <div className='casestudy-img'>
                              <img loading="lazy" src={CaseStudyimg.casestudyimage2} alt='Logo'/>
                            </div>
                            <div className='casestudy-content'>
                                <div className='casestudy-hd'>
                                    Lorem ipsum dolor sit amet
                                </div>
                                <div className='casestudy-txt'>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec non tellus quis quam molestie vulputate.
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col md={4} className='clo'>
                        <div className='casestudy-box'>
                            <div className='casestudy-img'>
                              <img loading="lazy" src={CaseStudyimg.casestudyimage1} alt='Logo'/>
                            </div>
                            <div className='casestudy-content'>
                                <div className='casestudy-hd'>
                                    Lorem ipsum dolor sit amet
                                </div>
                                <div className='casestudy-txt'>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec non tellus quis quam molestie vulputate.
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
                {/* <Row className='casestudy-row'>
                     <Col md={4} className='clo'>
                        <div className='casestudy-box'>
                            <div className='casestudy-img'>
                              <img loading="lazy" src={CaseStudyimg.casestudyimage1} alt='Logo'/>
                            </div>
                            <div className='casestudy-content'>
                                <div className='casestudy-hd'>
                                    Lorem ipsum dolor sit amet
                                </div>
                                <div className='casestudy-txt'>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec non tellus quis quam molestie vulputate.
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col md={4} className='clo'>
                        <div className='casestudy-box'>
                            <div className='casestudy-img'>
                              <img loading="lazy" src={CaseStudyimg.casestudyimage2} alt='Logo'/>
                            </div>
                            <div className='casestudy-content'>
                                <div className='casestudy-hd'>
                                    Lorem ipsum dolor sit amet
                                </div>
                                <div className='casestudy-txt'>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec non tellus quis quam molestie vulputate.
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col md={4} className='clo'>
                        <div className='casestudy-box'>
                            <div className='casestudy-img'>
                              <img loading="lazy" src={CaseStudyimg.casestudyimage1} alt='Logo'/>
                            </div>
                            <div className='casestudy-content'>
                                <div className='casestudy-hd'>
                                    Lorem ipsum dolor sit amet
                                </div>
                                <div className='casestudy-txt'>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec non tellus quis quam molestie vulputate.
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row className='casestudy-row'>
                     <Col md={4} className='clo'>
                        <div className='casestudy-box'>
                            <div className='casestudy-img'>
                              <img loading="lazy" src={CaseStudyimg.casestudyimage1} alt='Logo'/>
                            </div>
                            <div className='casestudy-content'>
                                <div className='casestudy-hd'>
                                    Lorem ipsum dolor sit amet
                                </div>
                                <div className='casestudy-txt'>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec non tellus quis quam molestie vulputate.
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col md={4} className='clo'>
                        <div className='casestudy-box'>
                            <div className='casestudy-img'>
                              <img loading="lazy" src={CaseStudyimg.casestudyimage2} alt='Logo'/>
                            </div>
                            <div className='casestudy-content'>
                                <div className='casestudy-hd'>
                                    Lorem ipsum dolor sit amet
                                </div>
                                <div className='casestudy-txt'>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec non tellus quis quam molestie vulputate.
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col md={4} className='clo'>
                        <div className='casestudy-box'>
                            <div className='casestudy-img'>
                              <img loading="lazy" src={CaseStudyimg.casestudyimage1} alt='Logo'/>
                            </div>
                            <div className='casestudy-content'>
                                <div className='casestudy-hd'>
                                    Lorem ipsum dolor sit amet
                                </div>
                                <div className='casestudy-txt'>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec non tellus quis quam molestie vulputate.
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
 */}
            </Container>
        </div>
        <Footer/>
    </div>
  )
}
