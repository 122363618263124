import React, { useEffect, useState } from "react";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import casestudyData from "../../../data/seocasestudy";
import { Link } from "react-router-dom";
const SeoCaseStudy = () => {
  const [mouseOver, setMouseOver] = useState(false);
  const [currentImage, setCurrentImage] = useState(0); // State to track current image index

  const images = ["./assets/founder.svg"];
  const path = "../../../assets/images/casestudy/";

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImage((prevIndex) => (prevIndex + 1) % images.length);
    }, 5000); // Change image every 3000 milliseconds (3 seconds)

    return () => clearInterval(interval); // Clean up interval on component unmount
  }, [images.length]); // Dependency array to ensure interval is set correctly


  const [currentTab, setCurrentTab] = useState("SEO");

  const currentContent = casestudyData[currentTab];

  return (
    <div className="mx-auto w-[90%]  mt-24">
      <div className="flex flex-col justify-center items-center">
        <h2
          className="mb-0 headline-heading abra-font white-bg"
        >
          Our Case Studies
        </h2>
        <h2 className="text-lg font-normal text-center cesess seo-casestudy-sub">
          Read in detail about the stories of victories
        </h2>
      </div>
      {/* <div
        className="my-6 overflow-x-auto   bottom-padding"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-evenly",
          whiteSpace: "nowrap",
        }}
      >
        {Object.keys(casestudyData).map((name, index) => {
          return (
            <p
              style={{
                cursor: "pointer",
                color: currentTab && currentTab === name ? "black" : "gray",
                margin: "0 1rem", // Add some margin for spacing
                textdecoration: "underline",
              }}
              className="font-semibold text-sm sm:text-lg tab-menu-item"
              onClick={() => setCurrentTab(name)}
              key={index}
            >
              {name && name}
            </p>
          );
        })}
      </div> */}

      <div className="md:h-[650px] flex-col md:flex md:flex-row gap-2">
        <div className="h-[100%] md:w-[50%] w-[100%] mb-2 md:mb-0 flex flex-col p-2 border-2 rounded-xl shadow-xl">
          <img
            alt="test"
            className="h-[50%]  mb-2 w-[100%]  rounded-xl"
            src={`${path}${currentContent[0].image.toLowerCase()}`}
          />
          <div className="case-mainn">
            <h2 className="font-semibold text-sm lg:text-2xl titecase">
						Website Visits Shot From 1K to a Massive 1.5 Lakhs
            </h2>
            {/* <h2 className="text-gray-500 lg:text-lg text-xs my-2">
            {currentContent[0].date}
          </h2> */}
            <p className="time-casestudy time-casestudy-seo">
              <i class="ri-time-fill"></i> March 8, 2024
            </p>
            <h2 className="lg:text-lg text-sm descrptn seo-casestudy-descrptn">
						Read in detail the challenges Kairali TMT faced, what we did to address them and the results they achieved.
            </h2>
          </div>
        </div>
        <div className="md:w-[50%] flex  flex-col h-[100%] justify-between gap-2">
          <div className="h-[49%] flex p-2 mobile-blockk border-2 rounded-xl shadow-xl mb-2 md:mb-0">
            <img
              alt="loading"
              className="w-[38%] mobile-h rounded-xl mr-2 mb-2 heightimg"
              src={`${path}${currentContent[1].image.toLowerCase()}`}
            />
            <div className=" side w-[88%]">
              <h2 className="font-semibold text-sm lg:text-2xl titecase">
							Generated 500+ Sales-qualified Leads in Just 6 Months
              </h2>
              <p className="time-casestudy time-casestudy-seo" >
                <i class="ri-time-fill"></i> March 8, 2024
              </p>
              {/* <h2 className="text-gray-500 my-2 lg:text-lg text-xs">
                {currentContent[1].date}
              </h2> */}
              <h2 className="lg:text-lg text-sm descrptn seo-casestudy-descrptn">
							Explore how we helped BOSQ increase organic lead generation and website visits. 
              </h2>
            </div>
          </div>
          <div className="h-[49%] flex  p-2 border-2 rounded-xl shadow-xl mb-2 md:mb-0 mobilehidee">
            <img
              alt="small img"
              className="w-[45%] mobile-h mb-2  rounded-xl mr-2 heightimg"
              src={`${path}${currentContent[2].image.toLowerCase()}`}
            />
            <div className=" side ">
              <h2 className="font-semibold text-sm lg:text-2xl titecase">
							Ranked a Skin Care Brand Above Giants Like Nykaa, Amazon, and Macaron
              </h2>
              <p className="time-casestudy time-casestudy-seo">
                <i class="ri-time-fill"></i> March 8, 2024
              </p>
              {/* <h2 className="text-gray-500 my-2 lg:text-lg text-xs">
                {currentContent[2].date}
              </h2> */}
              <h2 className="lg:text-lg text-sm descrptn seo-casestudy-descrptn">
							Read in detail the strategy we implemented to make Skin Bae stand out on Google. 
              </h2>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col justify-center items-center">
        <Link
          onMouseOver={() => setMouseOver(true)}
          onMouseLeave={() => setMouseOver(false)}
          className="btn learn-btn primary-bg abra-font"
          to="/about"
        >
          View More
          {mouseOver ? <ArrowForwardIcon /> : <ArrowOutwardIcon />}
        </Link>
      </div>
    </div>
  );
};
export default SeoCaseStudy;