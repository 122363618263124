import React from 'react';
import "./contactpage.css";
import { Container } from 'react-bootstrap';
import Form from "react-bootstrap/Form";
import NavBar from '../NavBar/NavBar';
import Footer from '../Footer/Footer';

function Contactpage() {
  const [result, setResult] = React.useState("");

  const onSubmit = async (event) => {
    event.preventDefault();
    setResult("Sending....");
    const formData = new FormData(event.target);

    formData.append("access_key", "f94f2cd3-c0a3-494b-a2d4-ca767a1fd557");

    const response = await fetch("https://api.web3forms.com/submit", {
      method: "POST",
      body: formData
    });

    const data = await response.json();

    if (data.success) {
      setResult("Form Submitted Successfully");
      event.target.reset();
    } else {
      console.log("Error", data);
      setResult(data.message);
    }
  };

  return (
    <div>
      <NavBar/>
      <div className='contactbanner'>
        <Container>
          <h1 className='firsttext'>Send a Message</h1>
          <p className='pharatext'>Interested in driving growth? Have a general question?<br/> We’re just an email away.</p>
          <div className="form-call-back">
            <Form onSubmit={onSubmit}>
              <Form.Group className="mb-4 form-quter" controlId="formName">
                <Form.Control type="text" placeholder="Name" name="Name" required />
              </Form.Group>
              <Form.Group className="mb-4 form-quter" controlId="formEmail">
                <Form.Control type="email" placeholder="Email" name="Email" required />
              </Form.Group>
              <Form.Group className="mb-4 form-quter" controlId="formPhone">
                <Form.Control type="text" placeholder="Phone" name="Number" required />
              </Form.Group>
              <Form.Group className="mb-4 form-quter" controlId="formPosition">
                <Form.Control type="text" placeholder="Position" name="Position" />
              </Form.Group>
              <Form.Group className="mb-4 form-quter" controlId="formCompany">
                <Form.Control type="text" placeholder="Company" name="Company" />
              </Form.Group>
              <Form.Group className="mb-4 form-quter" controlId="formLocation">
                <Form.Control type="text" placeholder="Location" name="Location" />
              </Form.Group>
              <Form.Group className="mb-4 form-quter" controlId="formFind">
                <Form.Control type="text" placeholder="How do you find us?" name="Find" />
              </Form.Group>
              <Form.Group className="mb-4 form-quter" controlId="formEnquiry">
                <Form.Control type="text" placeholder="Enquiry on" name="Enquiry" />
              </Form.Group>
              <Form.Group className="mb-4 form-quter" controlId="formBudget">
                <Form.Control type="text" placeholder="Marketing budget" name="Budget" />
              </Form.Group>
              <Form.Group className="mb-4 form-full" controlId="formMessage">
                <Form.Control as="textarea" rows={5} placeholder="Message" name="Message" required />
              </Form.Group>
              <div className="submit-from flex flex-col md:flex md:flex-row">
                <button
                  className="submit-btn abra-S w-[100%] md:w-[fit-content] flex items-center justify-center text-center"
                  type="submit"
                >
                  Submit Now
                </button>
              </div>
            </Form>
            {result && <p>{result}</p>}
          </div>
          <div className='contactaddress'>
            <h2 className='addresstitle'>Office Address</h2>
            <h2 className='addressdisc'>Haris & Consulting (OPC) Private Limited, 2nd Floor, 4 Wings Avenue Building, Above Wagonmart, Panniyankara, Kozhikode, Kerala 673003</h2>
          </div>
          <div className='contactmail'>
            <h2 className='addresstitle'>Email Us</h2>
            <h2 className='addressdisc'>bd@harisand.co</h2>
          </div>
        </Container>
      </div>
      
      <Footer/>
    </div>
  );
}

export default Contactpage;
