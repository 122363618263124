import React from 'react';
import { Container } from 'react-bootstrap';
import { Link, Outlet } from 'react-router-dom';
import footerlogo from '../../assets/Assets/qwe 10 1.svg';
import google from '../../assets/Assets/image 2.svg';
import './footer.css';
import ScrollToTop from '../Components/HomePage/ScrollToTop';
export default function Footer() {
  return (
    <div className='footer-wrapper'>
      <Container>
      <ScrollToTop/>
        <div className="row">
          <div className="col-lg-3 col-md-12">
            <a href='/' className="footer-box">
              <img src={footerlogo} className="footer-logo" alt="Logo" />
              <img src={google} className="footer-partner" alt="Google Partner" />
              <div className="footer-icons">
                <Link to='https://www.linkedin.com/company/harisand-co/'><i class="ri-linkedin-box-fill"></i></Link>
							  <Link to='https://www.facebook.com/harisandconsulting'><i class="ri-facebook-circle-fill"></i></Link>
							  <Link to='https://www.instagram.com/harisand.co/'><i class="ri-instagram-fill"></i></Link>
							  <Link to=''><i class="ri-twitter-x-fill"></i></Link>
              </div>
            </a>
          </div>
          <div className="col-lg-3">
            <div className="footer-box">
              <h4 className="mb-0 text-white footer-heading">Menu</h4>
              <ul>
                <li>
                  <Link to="/" className="footer-item">Home</Link>
                </li>
                <li>
                  <Link to="/service" className="footer-item">Services</Link>
                </li>
                <li>
                  <Link to="/blog" className="footer-item">Blog</Link>
                </li>
                <li>
                  <Link to="/people" className="footer-item">People</Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="footer-box">
              <h4 className="mb-0 text-white footer-heading">Important Links</h4>
              <ul>
                <li>
                  <Link to="/" className="footer-item">Case Studies</Link>
                </li>
                <li>
                  <Link to="/digital-marketing-agency-in-kerala"  className="footer-item">Digital Marketing Agency in Kerala</Link>
                </li>
                <li>
                  <Link to="/seo-company-in-kerala" className="footer-item">SEO</Link>
                </li>
                <li>
                  <Link to="/" className="footer-item">E-commerce Marketing</Link>
                </li>
                <li>
                  <Link to="/" className="footer-item">Lead Generation</Link>
                </li>
                <li>
                  <Link to="/" className="footer-item">Social Media Marketing</Link>
                </li>
              </ul>
              <Outlet />
            </div>
          </div>
          <div className="col-lg-3">
            <div className="footer-box">
              <h4 className="mb-0 text-white footer-heading">Get In Touch</h4>
              <ul>
                <li>
                  <a href="tel:+918075040330" className="footer-item">+91 8075 040 330</a>
                </li>
                <li>
                  <a href="mailto:bd@harisand.co" className="footer-item">bd@harisand.co</a>
                </li>
                <li>
                  <a href="#" className="footer-item">2nd floor, 4 WING Avenue, Panniyankara, 673003 Kozhikode, Kerala</a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="mobile-footer-only">
              <img src={google} className="google-img-mb" alt="Google Partner Mobile" />
              <div className="footer-icons-mob">
                <i className="ri-linkedin-box-fill"></i>
                <i className="ri-facebook-circle-fill"></i>
                <i className="ri-instagram-fill"></i>
                <i className="ri-twitter-x-fill"></i>
              </div>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="footer-copy">
              <p className="mb-0 copy-right-text light-grey-color text-center">
                Copyrights © 2024 by Team Haris&Co.
              </p>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}