import React from 'react'
import Slider from "react-slick";
import linkedin from '../../assets/l.png'
import facebook from '../../assets/f.png'
import youtube from '../../assets/y.png'
import tiktok from '../../assets/t.png'

const Platforms = () => {

	var settings = {
    dots: false,
    infinite: true,
    speed: 500,
		autoplay:true,
    slidesToShow: 4,
		arrows:false,
    slidesToScroll: 1,
  };




  return (
    <div className='container-main'>
			<div className="row">
				<div className="col-lg-12">
					<div className="paltform-main-dv">
						<h2 className='title-platform abra-font'>Platforms</h2>
						<p className='sub-title-platform'>Platforms and integrations to setup, finetune and scale the campaigns</p>
					</div>
				</div>
				<div className="col-lg-12">
					<div className="slider-platforms">
					<Slider {...settings}>
						<div className="mobile-logo-slider">
							<img src={linkedin} className="logo-mains" alt="" />
						</div>
						<div className="mobile-logo-slider">
							<img src={facebook} className="logo-mains" alt="" />
						</div>
						<div className="mobile-logo-slider">
							<img src={youtube} className="logo-mains" alt="" />
						</div>
						<div className="mobile-logo-slider">
							<img src={tiktok} className="logo-mains" alt="" />
						</div>
						<div className="mobile-logo-slider">
							<img src={linkedin} className="logo-mains" alt="" />
						</div>
						<div className="mobile-logo-slider">
							<img src={facebook} className="logo-mains" alt="" />
						</div>
						<div className="mobile-logo-slider">
							<img src={youtube} className="logo-mains" alt="" />
						</div>
						<div className="mobile-logo-slider">
							<img src={tiktok} className="logo-mains" alt="" />
						</div>
					</Slider>
					</div>
				</div>
			</div>
		</div>
  )
}

export default Platforms;













