import React from "react";
import ReactFullpage from "@fullpage/react-fullpage";
import './service.css';
import NavBar from "../NavBar/NavBar";
import ButtonIcon from '../../asset2/arrowButton.png';
import Socialmedia from '../../asset2/Social Media.png';
import Perfomance from '../../asset2/Perfomance.png';
import Production from '../../asset2/Production.png';
import Creative from '../../asset2/Creative.png';
import Digital from '../../asset2/PR & Communications.png';
import SEO from '../../asset2/SEO.png';
import Branding from '../../asset2/Branding.png';
import { Container } from "react-bootstrap";
import Web from '../../asset2/Web.png';
import Footer from '../../components/Footer/Footer';
import ServiceImage from "../../asset2/servise.png";


const anchors = [];
// const anchors = ["Marketing","SocialMedia", "PerfomanceMarketing", "Production","Creative","DigitalPR&Communications","SEO","Branding","WebDevelopment&UIUX"];

const Service = () => (
  <ReactFullpage
    anchors={anchors}
    navigation
    navigationTooltips={anchors}
    navigat
    sectionsColor={["#000", ]}  
    onLeave={(origin, destination, direction) => {
      console.log("onLeave event", { origin, destination, direction });
    }}
    render={({ state, fullpageApi }) => {
      console.log("render prop change", state, fullpageApi);
      return (
        <div className="servicewrpper">
          
          <div className="section  sectionnavbar" >
            <NavBar/>
          </div>

          <div className="section sectionservice">
            <div className="section1">
              <div className="servicebanerwrpper">
                    <Container>
                      <div className='ServiceWrapper'>
                          <div className='Headtext'>
                              <h1>Our Service</h1>
                          </div>
                          <div className='Headtext2'>
                              <h2>
                              Marketing <br/> Design <br/> Technology
                              </h2>
                          </div>
                      </div>
                  </Container>
                  <div className='ServiceImage'>
                    <img src={ServiceImage} alt="ServiceImg"></img>
                  </div>
              </div>

            </div>
          </div>


          <div className="section sectionservice" >
            <div className="section1">
                <Container>
                    <img src={Socialmedia} alt="Brandingimg" />
                    <div className="Section1Text">
                    <h2 className="h1Text">
                        Social Media<span className="SpanText"></span>
                    </h2>
                    <p>
                    Our team develops strategies that fuel your brand to thrive on Social Media platforms by driving engagement,<br/>  connecting with your audience and generating leads.
                    </p>
                    </div>
                    <div>
                    <a className="sliderButton" href="#">
                        Let's Talk
                        <span>
                        <img className="ButtonImg" src={ButtonIcon} alt="ButtonIcon" />
                        </span>
                    </a>
                    </div>
                </Container>
            </div>
          </div>

          <div className="section sectionservice section2"  >
                <div className="section1">
                        <Container>
                            <img src={Perfomance} alt="Brandingimg" />
                            <div className="Section1Text">
                            <h2 className="h1Text">
                                Perfomance Marketing<span className="SpanText"></span>
                            </h2>
                            <p>
                            Leveraging data and insights, we run and optimize your campaigns on Meta, Google, LinkedIn, Tik Tok and Snapchat,  delivering measurable results that drive conversions and maximize your ROI
                            </p>
                            </div>
                            <div>
                            <a className="sliderButton" href="#">
                                Let's Talk
                                <span>
                                <img className="ButtonImg" src={ButtonIcon} alt="ButtonIcon" />
                                </span>
                            </a>
                            </div>
                        </Container>
                </div>
          </div>
          
          <div className="section sectionservice section3" >
                <div className="section1">
                        <Container>
                            <img src={Production} alt="Brandingimg" />
                            <div className="Section1Text">
                            <h2 className="h1Text">
                                Production<span className="SpanText"></span>
                            </h2>
                            <p>
                            With our photography and videography, we deliver stunning visuals that captivate your audience and convey your brand message just right.
                            </p>
                            </div>
                            <div>
                            <a className="sliderButton" href="#">
                                Let's Talk
                                <span>
                                <img className="ButtonImg" src={ButtonIcon} alt="ButtonIcon" />
                                </span>
                            </a>
                            </div>
                        </Container>
                </div>
          </div>

          <div className="section sectionservice section4"  >
                <div className="section1">
                        <Container>
                            <img src={Creative} alt="Brandingimg" />
                            <div className="Section1Text">
                            <h2 className="h1Text">
                                Creative<span className="SpanText"></span>
                            </h2>
                            <p>
                            Expanding your brand's limits with bold ideas and strategic thinking, we deliver creatives that connect with your audience and spark conversations.
                            </p>
                            </div>
                            <div>
                            <a className="sliderButton" href="#">
                                Let's Talk
                                <span>
                                <img className="ButtonImg" src={ButtonIcon} alt="ButtonIcon" />
                                </span>
                            </a>
                            </div>
                        </Container>
                </div>
          </div>

          <div className="section sectionservice section5"  >
                <div className="section1">
                        <Container>
                            <img src={Digital} alt="Brandingimg" />
                            <div className="Section1Text">
                            <h2 className="h1Text">
                            Digital PR & Communications<span className="SpanText"></span>
                            </h2>
                            <p>
                            We lend a hand to you in fostering collaborations with relevant creators, influencers, and brands, helping you  amplify your brand, and drive tangible results.
                            </p>
                            </div>
                            <div>
                            <a className="sliderButton" href="#">
                                Let's Talk
                                <span>
                                <img className="ButtonImg" src={ButtonIcon} alt="ButtonIcon" />
                                </span>
                            </a>
                            </div>
                        </Container>
                </div>
          </div>


          <div className="section sectionservice section6" >
            <div className="section1">
              <Container>
               <img src={SEO} alt="Brandingimg" />
                  <div className="Section1Text">
                    <h2 className="h1Text"> SEO<span className="SpanText"></span></h2>
                            <p>
                            Our SEO department excels at crafting strategies that let you appear on top of Google search results. This translates  to more website visitors, leads and revenue.
                            </p>
                            </div>
                            <div>
                            <a className="sliderButton" href="#">
                                Let's Talk
                                <span>
                                <img className="ButtonImg" src={ButtonIcon} alt="ButtonIcon" />
                                </span>
                            </a>
                            </div>
                        </Container>
                </div>
          </div>


          <div className="section sectionservice section7"  >
                <div className="section1">
                        <Container>
                            <img src={Branding} alt="Brandingimg" />
                            <div className="Section1Text">
                            <h2 className="h1Text">
                            Branding<span className="SpanText"></span>
                            </h2>
                            <p>
                            We help you change the face of your brand by building a brand identity that resonates with your business and <br/> leaves a lasting impression.
                            </p>
                            </div>
                            <div>
                            <a className="sliderButton" href="#">
                                Let's Talk
                                <span>
                                <img className="ButtonImg" src={ButtonIcon} alt="ButtonIcon" />
                                </span>
                            </a>
                            </div>
                        </Container>
                </div>
          </div>


          <div className="sectionection8 sectionservice"  >
            <div className="section1">
              <Container>
                <img src={Web} alt="Brandingimg" />
                  <div className="Section1Text">
                    <h2 className="h1Text"> Web Development & UI UX<span className="SpanText"></span> </h2>
                            <p>
                            Our Web Developers are skilled in crafting websites that combine engaging UI, scroll-stopping content and user-friendliness, ultimately converting visitors into customers. 
                            </p>
                            </div>
                            <div>
                            <a className="sliderButton" href="#">
                                Let's Talk
                                <span>
                                <img className="ButtonImg" src={ButtonIcon} alt="ButtonIcon" />
                                </span>
                            </a>
                            </div>
                        </Container>
                </div>
          </div>

          <div className="section section8 sectionservice"  >
            <Footer/>
          </div>

        </div>
      );
    }}
  />
);
export default Service;