import React from "react";
import Hero from "../Components/HomePage/Hero";
import Brands from "../Components/HomePage/Brands";
import About from "../Components/HomePage/About";
import Frame from "../Components/HomePage/Frame";
import Whatwe from "../Components/HomePage/Whatwe";
import Headlines from "../Components/HomePage/Headlines";
import Consult from "../Components/HomePage/Consult";
import Clients from "../Components/HomePage/Clients";
import Chat from "../Components/HomePage/Chat";
import Map from "../Components/HomePage/Map";
import Faq from "../Components/HomePage/Faq";
import CaseStudy from "../Components/HomePage/CaseStudy";
// import Footer from "../.././Outlets/Footer";
import Mobileslider from "../Components/HomePage/Mobileslider";
import Mobileslidertwo from "../Components/HomePage/Mobileslidertwo";
import Brandbelieve from "../Components/HomePage/Brandbelieve";
import Mobileservice from "../Components/HomePage/Mobileservice";
import Navbar from "../../Outlets/Navbar"
import Seocontent from "../Components/HomePage/Seocontent";
import NavBar from "../NavBar/NavBar";
import Footer from "../Footer/Footer";
import DocumentMeta from "react-document-meta";
import { Helmet } from 'react-helmet';
const Section2 = () => {
	const meta = {
		title: 'Revenue Focused Digital Marketing Agency in Kerala | Haris&Co',
		description: 'Grow your business with the best digital marketing agency in Kerala. We, at Haris&Co., offer all the essential digital marketing services to boost your business revenue.',
		canonical: 'https://harisand.co/digital-marketing-agency-in-kerala',
		meta: {
			charset: 'utf-8',
			name: {
				keywords: 'react,meta,document,html,tags'
			}
		}
	};
	
	return (
		<div className="white-bgs relative z-50">
			<Helmet>
        <title>Revenue Focused Digital Marketing Agency in Kerala | Haris&Co</title>
        <meta name="description" content="Grow your business with the best digital marketing agency in Kerala. We, at Haris&Co., offer all the essential digital marketing services to boost your business revenue." />
      </Helmet>
		<DocumentMeta {...meta}/>
			<Headlines />

			<div className="container-main">
				<Consult />
			</div>
			<CaseStudy />
			
			<Clients />
			<Chat />
			<Map />
			<Faq />
			<Seocontent/>
			

		</div>
	);
};

const Home = () => {
	return (
		<div className="black-bg land-main main-wrapper relative">

			<div className="hide-desk-land">
			<NavBar/>
				</div>
		<Navbar/>
		
			<div className="relative z-30 home-page black-bg">
				<div className="container-main">
					<Hero />
					{/* <Hero/> */}
				</div>
				<div className="underlinemain"></div>

				<div className="">
				<Brandbelieve/>
				<Mobileslider/>
				<Mobileslidertwo/> 
					<Brands />
					<About />
					<Frame />
					<Mobileservice/>
					<Whatwe />
					<div className="black-bg divider-space"></div>
					<Section2 />
				</div>
			</div>
			<Footer/>
			{/* <Footer /> */}
		</div>
	);
};

export default Home;
