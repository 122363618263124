import React from 'react'
import Accordion from 'react-bootstrap/Accordion';	
const FaqSeo = () => {
	return (
		<div className='faq-section'>
			<div className="container-main">
				<div className="row">
					<div className="col-lg-12">
						<div className="faq-main">
							<h2 className='faq-heading mb-0 text-center abra-font'>FAQs</h2>
							<div className="collapse-main">
								<Accordion defaultActiveKey="0">
									<Accordion.Item eventKey="0">
										<Accordion.Header> 1.What is SEO?
										</Accordion.Header>
										<Accordion.Body>
											<p className='mb-0 faq-sub'>SEO is the technique by which you can make your website appear on top of the search engine results when someone makes a search. Consequently, this can lead to an increase in website visitors and lead generation.
											 </p>
										</Accordion.Body>
									</Accordion.Item>
									<Accordion.Item eventKey="1">
										<Accordion.Header>Why is SEO important for my business?
										</Accordion.Header>
											<Accordion.Body>
												<p className='mb-0 faq-sub'>DataReportal's study states that nearly 50% of internet users use search engines to learn more about brands. So, being there on the top when they search is truly important. They can potentially become your next customer.
												</p>
											</Accordion.Body>
									</Accordion.Item>
									<Accordion.Item eventKey="2">
										<Accordion.Header>How long does SEO take to show results?
										</Accordion.Header>
											<Accordion.Body>
												<p className='mb-0 faq-sub'>SEO is an ongoing and organic process, but you can expect to see initial results within 3-6 months with consistent effort. Long-term SEO strategy yields sustainable results.

 </p>
											</Accordion.Body>
									</Accordion.Item>
									<Accordion.Item eventKey="3">
										<Accordion.Header>How will you measure the success of your SEO strategy?
										</Accordion.Header>
											<Accordion.Body>
												<p className='mb-0 faq-sub'>We use tools like Google Search Console, SEMrush and Ubersuggest to track website traffic keyword ranking, leads generated, and conversions. 
												Apart from that, we use tools like Microsoft Clarity and Hotjar to analyse how the users are scrolling through your website, where they are clicking and how much content they are reading. This helps us to tweak the UX and the content if required												 </p>
											</Accordion.Body>
									</Accordion.Item>
									<Accordion.Item eventKey="4">
										<Accordion.Header>How can my website speed affect SEO?
										</Accordion.Header>
											<Accordion.Body>
												<p className='mb-0 faq-sub'>Basically, when your website is slow to load, the visitor will bounce off. This can increase the bounce rate, which is something Google doesn't encourage. 

Hence, this can affect your SEO efforts adversely. This is the reason why we do Technical SEO and sort every technical issue at the initial stage of our SEO projects.
 </p>
											</Accordion.Body>
									</Accordion.Item>
									<Accordion.Item eventKey="5">
										<Accordion.Header> Why should I hire an SEO agency? Can’t I do it myself?
										</Accordion.Header>
											<Accordion.Body>
												<p className='mb-0 faq-sub'>You can certainly do SEO yourself, but it's like managing your own investments – time-consuming and requiring constant learning. 

However, when you have an expert SEO company that stays on top of the latest algorithms, you can worry less about the SEO part and concentrate more on your business.
 </p>
											</Accordion.Body>
									</Accordion.Item>
									<Accordion.Item eventKey="6">
										<Accordion.Header> How will you maintain the results?
										</Accordion.Header>
											<Accordion.Body>
												<p className='mb-0 faq-sub'>SEO is an ongoing process. Once you stop it, you can lose your ranks. Hence, in order to maintain the results, we consistently keep our work rolling and tweak the strategies to make good results great over time.

 </p>
											</Accordion.Body>
									</Accordion.Item>
								</Accordion>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default FaqSeo;