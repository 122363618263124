import Navbar from '../../Outlets/Navbar'
import Footer from '../Footer/Footer'
import founder from '../../../src/assets/Assets/founder.png';
import slidetwo from '../../../src/assets/Assets/mobslide1.webp';
import slidethree from '../../../src/assets/Assets/mobslide2.webp';
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import seoimg from '../../../src/assets/vd.svg'
import mob from '../../../src/assets/Assets/mobgrid.webp';
import Brands from '../../components/Components/HomePage/Brands'
import SeoTeam from '../SeoLandingPage/SeoComponents/SeoTeam';
import WhatweSeo from '../../components/Components/HomePage/WhatweSeo'
import Clients from '../Components/HomePage/SeoClients';
import Chat from '../Components/HomePage/Chat';
import Map from '../Components/HomePage/Map';
import Faq from '../Components/HomePage/Faq';
import SeoPageBrands from '../Components/HomePage/SeoPageBrands';
import SeaProposal from '../SeoLandingPage/SeoComponents/SeaProposal';
import Results from '../SeoLandingPage/SeoComponents/Results';
import SeoCaseStudy from '../Components/HomePage/SeoCaseStudy';
import SeoPageContent from '../Components/HomePage/SeoPageContent';
import Brandbelieve from '../Components/HomePage/Brandbelieve';
import SeoMobileSlider from '../Components/HomePage/SeoMobileSlider';
import SeoMobileSliderTwo from '../Components/HomePage/SeoMobileSliderTwo';
import ad from '../../assets/ad.mp4'
import { useRef } from 'react';
// import logo from "../Assets/logo.svg";
import logo from "../../assets/Assets/logo.svg";
import { ArrowForward } from "@mui/icons-material";
import { Modal, Button } from 'react-bootstrap'; // Import from react-bootstrap
// import modalimage from '.././Assets/popupweb.png'
import modalimage from '../../assets/Assets/popupweb.png'
import modalimagemobile from '../../assets/Assets/popupmob.webp'
import SocialMediaTeam from './SocialMediaTeam';
import menu from '../../assets/Assets/menubar.svg'
import { Helmet } from 'react-helmet';
import SocialMediaCreatives from './SocialMediaCreatives';
import WhatWeSocialMedia from '../../components/SocialMedia/WhatWeSocialMedia'
import Platforms from './Platforms';
import Campaigns from './Campaigns';





const SocialMedia = () => {

  const [mouseOverProposal, setMouseOverProposal] = useState(false);
  const [mouseOverConsult, setMouseOverConsult] = useState(false);
  const [currentImage, setCurrentImage] = useState(0);
  // const [showModal, setShowModal] = useState(false);

  const images = [seoimg, founder, slidetwo, slidethree];

  const [mouseOver, setMouseOver] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const [result, setResult] = React.useState("");
  const onSubmit = async (event) => {
    event.preventDefault();
    setResult("Sending....");
    const formData = new FormData(event.target);

    formData.append("access_key", "f94f2cd3-c0a3-494b-a2d4-ca767a1fd557");

    const response = await fetch("https://api.web3forms.com/submit", {
      method: "POST",
      body: formData
    });

    const data = await response.json();

    if (data.success) {
      setResult("Form Submitted Successfully");
      event.target.reset();
    } else {
      console.log("Error", data);
      setResult(data.message);
    }
  };
  return (
    <div>
      <Helmet>
        <title>A ROI-focused SEO Company in Kerala | Haris&Co</title>
        <meta name="description" content="An ROI-focused SEO company in Kerala that brings you leads and sales, not just traffic, clicks, and impressions." />
        <link rel="canonical" href="https://harisand.co/seo-company-in-kerala" />
      </Helmet>
      <div className="nav-main black-bg  nav-show-home" >
        <div className="container-main">
          <div className="navbar-main bs-none">
            <a className="logo-nav" href="/">
              <img src={logo} className="logo-main" alt="" />
            </a>
            <div className="item-nav">
              <ul className="mb-0 nav-item-list-dv">
                <li>
                  <Link className="nav-item active-nav" to="/">
                    Home
                  </Link>
                </li>
                <li>
                  <Link className="nav-item" to="/service">
                    Services
                  </Link>
                </li>
                <li>
                  <Link className="nav-item" to="/">
                    Culture
                  </Link>
                </li>
                <li>
                  <Link className="nav-item" to="/">
                    Case Studies
                  </Link>
                </li>
                <li>
                  <Link className="nav-item" to="/Contactpage">
                    Contact Us
                  </Link>
                </li>
              </ul>
            </div>
            <div
              onMouseOver={() => setMouseOver(true)}
              onMouseLeave={() => setMouseOver(false)}
              className="btn-nav"
            >
              <Link
                className="btn proposal-btn primary-bg abra-font"
                onClick={() => setShowModal(true)} // Trigger the modal on click
              >
                GET A PROPOSAL{" "}
                {mouseOver ? (
                  <ArrowForward />
                ) : (
                  <ArrowOutwardIcon />
                )}
              </Link>
            </div>
            <img src={menu} className="menu-icon" alt="" />
          </div>
        </div>

        <Modal className="modalmaster" size="lg" show={showModal} onHide={() => setShowModal(false)} centered>
          <Modal.Body>
            <div className="modal-main-div">
              <div className="modal-half">
                <img src={modalimage} className="popimage" alt="" />
                <img src={modalimagemobile} className="popimagemobile" alt="" />
              </div>
              <div className="modal-half-p">
                <div className="modal-contentt">
                  <i class="ri-close-circle-line closeicon" onClick={() => setShowModal(false)}></i>
                  <h3 className="callback abra-font">We'll Call You Back</h3>
                  <p className="popup-subtext abra-font">Our sales team will contact you soon to gather the <br /> details needed to prepare your proposal.</p>
                  <div className="modal-form">
                    <form onSubmit={onSubmit}>
                      <div className="form-group form-half-popup">
                        <label for="exampleInputEmail1">Full Name</label>
                        <input type="text" name="Name" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="" />
                      </div>
                      <div className="form-group form-half-popup">
                        <label for="exampleInputEmail1">Company*</label>
                        <input type="text" name="Company" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="" />
                      </div>
                      <div className="form-group form-half-popup">
                        <label for="exampleInputEmail1">Phone*</label>
                        <input type="text" name="Phone" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="" />
                      </div>
                      <div className="form-group form-half-popup">
                        <label for="exampleInputEmail1">Company email*</label>
                        <input type="email" name="mail" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="" />
                      </div>
                      <div class="form-group form-textarea">
                        <label for="exampleFormControlTextarea">Message*</label>
                        <textarea class="form-control mt-2" id="exampleFormControlTextarea1" rows="4"></textarea>
                      </div>
                      <button className="submit-button-form btn abra-font" type="submit">Submit Now</button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
      <div className='container-main'>
        <div className="award-winning top-seo" id="homeee">
          <div className="md:w-[50%] w-[100%]">
            <h1 className="mb-0 st-hero">
              A Rapidly Growing SEO Company in Kerala
            </h1>
            <h2 className="mb-0 heading-text-hero abra-font fw">
              Rank. Reach. Revenue
            </h2>
            <p className="mb-0 sub-text-hero">
              99% of SEO companies give you ranks. Just 1% brings you{" "}
              revenue-inspiring results. We fall in the second category.{""}
            </p>
            <div className="button-dv">
              <Link
                onMouseOver={() => setMouseOverProposal(true)}
                onMouseLeave={() => setMouseOverProposal(false)}
                className="btn proposal-btn primary-bg abra-font"
                onClick={() => setShowModal(true)}
              >
                GET IN PROPOSAL
                {mouseOverProposal ? <ArrowForwardIcon /> : <ArrowOutwardIcon />}
              </Link>
              <Link
                onMouseOver={() => setMouseOverConsult(true)}
                onMouseLeave={() => setMouseOverConsult(false)}
                className="btn proposal-btn abra-font consult-bttn"
                to=""
              >
                Let’s Chat
                {mouseOverConsult ? <ArrowForwardIcon /> : <ArrowOutwardIcon />}
              </Link>
            </div>
            {/* <div className="count-dv mobile-none">
          <div className="w-[25%] border-lefttt">
            <h4 className="mb-0 number-text abra-font">10,000+</h4>
            <p className="mb-0 number-text-sub abra-font  md:text-md text-xs">
            Keywords in <span className="block-text abra-font">Top 10  </span>
            </p>
          </div>
          <div className="w-[25%] left-space border-lefttt">
            <h4 className="mb-0 number-text abra-font"  >50,000+</h4>
            <p className="mb-0 number-text-sub abra-font  md:text-md text-xs">
            Leads Generated{" "}
            </p>
          </div>
            <div className="w-[25%] left-space border-lefttt">
              <h4 className="mb-0 number-text abra-font">8M+</h4>
              <p className="mb-0 number-text-sub abra-font  md:text-md text-xs">
              Total Impressions
              </p>
            </div>
            <div className="w-[25%] left-space md:border-r-2 borderno md:border-r-white">
              <h4 className="mb-0 number-text abra-font"></h4>
              <p className="mb-0 number-text-sub abra-font  md:text-md text-xs">
                {" "}
                <span className="block-text abra-font"></span>{" "}
              </p>
            </div>
        </div> */}
          </div>
          <div className="ceo-image">
            <img src={images[currentImage]} className="founder-img mobileno seo-space fade-image" alt="" data-toggle="modal" data-target="#ceoModal" />
            <img src={mob} className="founder-img mobileyes mobile-image-hero" alt="" />
          </div>

          <div className="modal fade" id="ceoModal" tabIndex="-1" aria-labelledby="ceoModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content model-width">
                {/* <div className="modal-header">
        <h5 className="modal-title" id="ceoModalLabel">CEO Information</h5>
        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div> */}
                <button type="button" className="closeeeee" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
                <div className="modal-body modal-padding">
                  <video width="" height="" controls autoPlay muted>
                    <source src={ad} type="video/mp4" />
                  </video>
                  {/* <p>This is where you can add the CEO's information or any other content.</p> */}
                </div>
                {/* <div className="modal-footer">
        <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
      </div> */}
              </div>
            </div>
          </div>


          {/* <div className="count-dv mobile-block">
        <div className="mdain">
          <div className="w-[50%] md:border-r-2 md:border-r-white bbb">
            <h4 className="mb-0 number-text abra-font ">10,000+</h4>
            <p className="mb-0 number-text-sub abra-font  md:text-md text-xs">
            Keywords in  <span className="block-text sblock abra-font">Top 10   </span>
            </p>
          </div>
          <div className="w-[50%] left-space md:border-r-2 md:border-r-white gg">
            <h4 className="mb-0 number-text abra-font">50,000+</h4>
            <p className="mb-0 number-text-sub abra-font  md:text-md text-xs">
            Leads Generated
            <span className="block-text abra-font">Top 10</span>{" "}
            </p>
          </div> 
        </div>
        <div className="mdain">
          <div className="w-[50%]  md:border-r-2 md:border-r-white tcntr">
            <h4 className="mb-0 number-text abra-font">8 M+ </h4>
            <p className="mb-0 number-text-sub abra-font  md:text-md text-xs">
            Total Impressions
            {" "}
            </p>
          </div>
         
        </div>
      </div> */}
        </div>
      </div>
      <div className="seo-brands">
        <SeoPageBrands />
        <Brandbelieve />
        <SeoMobileSlider />
        <SeoMobileSliderTwo />
      </div>
      <SocialMediaTeam />
      <SocialMediaCreatives/>
      <WhatWeSocialMedia />
      <Platforms/>
      <Campaigns/>
      <Results />
      <div className="white-bg white-top">
        <SeaProposal />
        <SeoCaseStudy />
        <Clients />
        <Chat />
        <Map />
        <Faq />
        <SeoPageContent />
      </div>
      <Footer />
    </div>
  )
}

export default SocialMedia;