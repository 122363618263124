import React, { useState } from "react";
import CallReceivedIcon from "@mui/icons-material/CallReceived";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import arrow from '../../../assets/Assets/Vector (4).svg'; // Assuming arrow image is local

const images = [  // Array of local image paths or objects
  require('../../../assets/Assets/suprabadam.webp'),  // Adjust file paths as needed
  require('../../../assets/Assets/the hindu.webp'),
  require('../../../assets/Assets/indin.png'),
  require('../../../assets/Assets/ads of the world 1.webp'),
];

const SlideComp = ({ image }) => { // Destructure image prop
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      onMouseEnter={(e) => {
        setIsHovered(true);
      }}
      onMouseLeave={() => setIsHovered(false)}
      className="flex items-center rounded-xl"
    >
      <div className="relative rounded-xl">
        <img className="h-full w-full object-cover rounded-xl" src={image} alt="img" />
        {isHovered && (
          <div className="pointer-events-none opacity-70 absolute top-0 right-0 h-[100%] w-[100%] bg-purple rounded-xl">
            <div className="absolute bottom-9 right-10">
              <img src={arrow} alt="" />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const Headlines = () => {
  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "30%",
    slidesToShow: 1,
    speed: 500,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          centerPadding: "15%", // Adjust for smaller screens
        },
      },
    ],
  };

  return (
    <div className="overflow-x-clip slider-container">
      <div className="headline-section white-bg mb-12">
        <h2 className="mb-0 headline-heading abra-font white-bg">
          Making Headlines
        </h2>
        <p className="mb-0 sub-head-headline white-bg">
          To get featured in multiple news articles gives us immense joy
        </p>
      </div>

      <Slider arrows autoplay dots {...settings}>
        {images.map((image) => ( // Render slides using images array
          <SlideComp key={image} image={image} /> // Provide unique keys
        ))}
      </Slider>
    </div>
  );
};

export default Headlines;
