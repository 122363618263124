import React from "react";
import Slider from "react-slick";
import one from "../../../assets/MOB SEO logo.png";
import two from "../../../assets/seo mob logo.png";
import three from "../../../assets/seo mob logo1.png";

const SeoMobileSlider = () => {


	var settings = {
    dots: false,
    infinite: true,
    speed: 500,
		autoplay:true,
    slidesToShow: 1,
		arrows:false,
    slidesToScroll: 1,
  };


	return (
		<div className="container-main">
		<div className="slider-mobile slidertop">
		<Slider {...settings}>
		<div className="mobile-logo-slider">
			<img src={one} className="logo-main" alt="" />
		</div>

		<div className="mobile-logo-slider">
			<img src={two} className="logo-main" alt="" />
		</div>
		<div className="mobile-logo-slider">
			<img src={three} className="logo-main" alt="" />
		</div>
	
	</Slider>
		</div>
		</div>
	)
}

export default SeoMobileSlider;