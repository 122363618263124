import React from 'react'
import team from '../../../assets/Frame 60.png'
const SeoTeam = () => {
  return (
    <div className='container-main bottom-seo'>
       <div className="seo-team-content">
          <div className="seo-team-content-dv-half">
            <h2 className='seo-main-text-tt abra-font'>The SEO Company That <span>Drives Leads, Sales and </span> Revenue for Your Business</h2>
          </div>
          <div className="seo-team-content-dv">
            <p className='result-sub-text'>As a result-oriented SEO company in Kerala, we help you rise to the forefront of the search results and bring more sales-qualified visitors to your website. Plus, our expert optimisation turns visitors into customers.</p>
          </div>
          <div className="seo-team-image-dv">
            <img src={team} alt="" />
          </div>
       </div>
    </div>
  )
}

export default SeoTeam;