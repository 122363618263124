import React from 'react'
import NavBar from '../NavBar/NavBar'
import PeopleBaner from './PeopleBaner/PeopleBaner'
import './people.css'
import Cursor from '../CursorAnimation/Cursor'
import Cursordark from '../CursorAnimation/Cursordark'
import PeopleDepartmentHeads from './DepartmentHeads/PeopleDepartmentHeads'
import PeopleTeam from './PeopleTeam/PeopleTeam'
import PeopleBannerMob from './PeopleBannerMob'
import Footer from '../Footer/Footer'

export default function people() {
  return (
    <div className='people'>
       
        <NavBar/>
        <PeopleBaner/>
        <PeopleBannerMob/>
        <PeopleDepartmentHeads/>
        <PeopleTeam/>
        <Cursordark/>
        <Footer/>
    </div>
  )
}
