import React from "react";
import Slider from "react-slick";
import twentyseven from "../../../assets/Assets/white-logo/med7 w logo.png";
import twentyeight from "../../../assets/Assets/white-logo/milliondots w logo.png";
import thirty from "../../../assets/Assets/white-logo/movinzo w logo.png";
import thirtytwo from "../../../assets/Assets/white-logo/mralfred w logo.png";
import thirtythree from "../../../assets/Assets/white-logo/myoffice w logo.png";
import thirtyfour from "../../../assets/Assets/white-logo/nanofm w logo.png";
import thirtyfive from "../../../assets/Assets/white-logo/natural fresh w logo.png";
import thirtysix from "../../../assets/Assets/white-logo/natya w logo.png";
import thirtyseven from "../../../assets/Assets/white-logo/organic habits w logo.png";
import thirtyeight from "../../../assets/Assets/white-logo/popees w logo.png";
import thirtynine from "../../../assets/Assets/white-logo/qaadu w logo.png";
import forty from "../../../assets/Assets/white-logo/sg education w logo.png";
import fortyone from "../../../assets/Assets/white-logo/shanoor w logo.png";
import fortytwo from "../../../assets/Assets/white-logo/silver edge w logo.png";
import fortythree from "../../../assets/Assets/white-logo/skillbus  w logo.png";
import fortyfour from "../../../assets/Assets/white-logo/skinbae w logo.png";
import fortyfive from "../../../assets/Assets/white-logo/TCT w logo.png";
import fortysix from "../../../assets/Assets/white-logo/trends w logo.png";
import fortyseven from "../../../assets/Assets/white-logo/trojan w logo.png";
import fortyeight from "../../../assets/Assets/white-logo/visaguy w logo.png";
import fortynine from "../../../assets/Assets/white-logo/watiaa w logo.png";
import fifty from "../../../assets/Assets/white-logo/westbrook w logo.png";
import fiftyone from "../../../assets/Assets/white-logo/wildtree w logo.png";
import fiftytwo from "../../../assets/Assets/white-logo/yaslin w logo.png";
import six from "../../../assets/Assets/white-logo/Driver w logo.png";
import seven from "../../../assets/Assets/white-logo/Iretex w logo.png";
import eight from "../../../assets/Assets/white-logo/kiddie bus w logo.png";
import nine from "../../../assets/Assets/white-logo/Noam w logo.png";
import thirteen from "../../../assets/Assets/white-logo/algate w logo.png";
import sixteen from "../../../assets/Assets/white-logo/bliss w logo.png";


const Mobileslider = () => {


	var settings = {
    dots: false,
    infinite: true,
    speed: 500,
		autoplay:true,
    slidesToShow: 3,
    slidesToScroll: 1,
		arrows:false,
  };


	return (
		<div className="container-main">
		<div className="slider-mobile tbs">
		<Slider {...settings}>
		<div className="mobile-logo-slider">
			<img src={six} className="logo-main" alt="" />
		</div>

		<div className="mobile-logo-slider">
			<img src={seven} className="logo-main" alt="" />
		</div>
		<div className="mobile-logo-slider">
			<img src={eight} className="logo-main" alt="" />
		</div>
		<div className="mobile-logo-slider">
			<img src={nine} className="logo-main" alt="" />
		</div>
		<div className="mobile-logo-slider">
			<img src={thirteen} className="logo-main" alt="" />
		</div>
		<div className="mobile-logo-slider">
			<img src={sixteen} className="logo-main" alt="" />
		</div>
		<div className="mobile-logo-slider">
			<img src={thirtyfive} className="logo-main" alt="" />
		</div>
		<div className="mobile-logo-slider">
			<img src={thirtysix} className="logo-main" alt="" />
		</div>
		<div className="mobile-logo-slider">
			<img src={thirtyseven} className="logo-main" alt="" />
		</div>
		<div className="mobile-logo-slider">
			<img src={thirtyeight} className="logo-main" alt="" />
		</div>
		<div className="mobile-logo-slider">
			<img src={thirtynine} className="logo-main" alt="" />
		</div>
		<div className="mobile-logo-slider">
			<img src={forty} className="logo-main" alt="" />
		</div>
		<div className="mobile-logo-slider">
			<img src={fortyone} className="logo-main" alt="" />
		</div>
		<div className="mobile-logo-slider">
			<img src={fortytwo} className="logo-main" alt="" />
		</div>
		<div className="mobile-logo-slider">
			<img src={fortythree} className="logo-main" alt="" />
		</div>
		<div className="mobile-logo-slider">
			<img src={fortyfour} className="logo-main" alt="" />
		</div>
		<div className="mobile-logo-slider">
			<img src={fortyfive} className="logo-main" alt="" />
		</div>
		<div className="mobile-logo-slider">
			<img src={fortysix} className="logo-main" alt="" />
		</div>
		<div className="mobile-logo-slider">
			<img src={fortyseven} className="logo-main" alt="" />
		</div>
		<div className="mobile-logo-slider">
			<img src={fortyeight} className="logo-main" alt="" />
		</div>
		<div className="mobile-logo-slider">
			<img src={fortynine} className="logo-main" alt="" />
		</div>
		<div className="mobile-logo-slider">
			<img src={fifty} className="logo-main" alt="" />
		</div>
		<div className="mobile-logo-slider">
			<img src={fiftyone} className="logo-main" alt="" />
		</div>
		<div className="mobile-logo-slider">
			<img src={fiftytwo} className="logo-main" alt="" />
		</div>

	</Slider>
		</div>
		</div>
	)
}

export default Mobileslider