import React, { useState } from "react";
import { Link } from "react-router-dom";
import call from "../../../assets/Assets/Vector.svg";
import msg from "../../../assets/Assets/Vector (1).svg";
import loc from "../../../assets/Assets/Vector (2).svg";
import Form from "react-bootstrap/Form";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";

const Chat = () => {
	// const [mouseOver, setMouseOver] = useState(false);

	const [result, setResult] = React.useState("");

	const onSubmit = async (event) => {
	  event.preventDefault();
	  setResult("Sending....");
	  const formData = new FormData(event.target);
  
	  formData.append("access_key", "f94f2cd3-c0a3-494b-a2d4-ca767a1fd557");
  
	  const response = await fetch("https://api.web3forms.com/submit", {
		method: "POST",
		body: formData
	  });
  
	  const data = await response.json();
  
	  if (data.success) {
		setResult("Form Submitted Successfully");
		event.target.reset();
	  } else {
		console.log("Error", data);
		setResult(data.message);
	  }
	};
  

	return (
		<div className="live-chat black-bg">
			<div className="container-main">
				<div className="row">
					<div className="col-lg-6">
						<div className="chat-dv">
							<h2 className="text-white abra-font chat-heading">
								Let's Have a Chat
							</h2>
							<p className="light-greay-color chat-sub ">
								Give us a call or email us to learn more about
								our services. <br /> Need a face-to-face discussion?
								Feel free to visit us. 
							</p>
							<div className="location-div">
								<div className="flex flex-col h-[100%]">
									<div className="flex-col md:flex md:flex-row md:items-center">
										<div className="w-[100%] h-[100%] md:w-[50%] flex">
											<img
												src={call}
												height={40}
												width={40}
												className="loc-icon"
												alt=""
											/>
											<div className="w-[100%] h-[100%]  md:w-[100%] location-text flex flex-col justify-center">
												<p className="mb-0  text-white">
													For More Enquires
												</p>
												<Link
													className="location-link w-[100%] text-white"
													to="https://wa.me/+918075040330"
												>
													+91 8075 040 330
												</Link>
											</div>
										</div>
										<div className="w-[100%] h-[100%] md:w-[50%] flex mt-4">
											<img
												src={msg}
												height={40}
												width={40}
												className="loc-icon"
												alt=""
											/>
											<div className="location-text flex flex-col justify-center">
												<p className="mb-0 enq-text text-white">
													Email Us At
												</p>
												<Link
													className="location-link text-white"
													to="mailto:bd@harisand.co" 
												>
													bd@harisand.co
												</Link>
											</div>
										</div>
									</div>
									<div className="w-[100%] h-16 md:w-[100%] flex mt-4">
										<img
											src={loc}
											height={40}
											width={40}
											className="loc-icon"
											alt=""
										/>
										<div className="location-text flex items-center">
											<p className="mb-0 enq-text text-white">
												2nd floor, 4 WING Avenue
												,Panniyankara, 673003 <br /> Kozhikode,
												Kerala{" "}
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="">
						<div class="w-[100%] lg:w-1/2 mt-12 lg:mt-0 bg-[#7744d5] p-8 pt-6 pb-14 brds text-white lg:absolute lg:right-0 lg:-top-40">
							<h2 className="mb-0 abra-font call-back-text">
								Get a Callback
							</h2>
							<div className="form-call-back">
								<Form onSubmit={onSubmit} >
									<Form.Group
										className="mb-4 form-half"
										controlId="formBasicEmail"
									>
										<Form.Label>Full Name</Form.Label>
										<Form.Control
											type="text"
											placeholder=""
											name="Name" 
											required
										/>
									</Form.Group>
									<Form.Group
										className="mb-4 form-half"
										controlId="formBasicEmail"
									>
										<Form.Label>Organization*</Form.Label>
										<Form.Control
											type="text"
											placeholder=""
											name="Organization" 
											
										/>
									</Form.Group>
									<Form.Group
										className="mb-4 form-half"
										controlId="formBasicEmail"
									>
										<Form.Label>Phone*</Form.Label>
										<Form.Control
											type="text"
											placeholder=""
											name="Phone" 
											required
										/>
									</Form.Group>
									<Form.Group
										className="mb-4 form-half"
										controlId="formBasicEmail"
									>
										<Form.Label>Company email*</Form.Label>
										<Form.Control
											type="email"
											placeholder=""
											name="email" 
											required
										/>
									</Form.Group>
									<Form.Group
										className="mb-4 form-full"
										controlId="exampleForm.ControlTextarea1"
									>
										<Form.Label>
											Message*
										</Form.Label>
										<Form.Control as="textarea" name="Message" 
											required rows={5} />
									</Form.Group>
									<div className="submit-from flex flex-col md:flex md:flex-row">
									<div className="bt-wrpp">
									<button
										className="submit-btn abra-font  w-[100%] md:w-[fit-content] flex items-center justify-center text-center"
										type="submit"
										// onMouseOver={() => setMouseOver(true)}
										// onMouseLeave={() => setMouseOver(false)}
									>
										Submit Now
										{/* {mouseOver ? (
											<ArrowForwardIcon />
										) : (
											<ArrowOutwardIcon />
										)} */}
									</button>
									{result && <p>{result}</p>}

									</div>
									<div className="icons-button mt-8 md:mt-0">
										<Link to='https://www.linkedin.com/company/harisand-co/'><i class="ri-linkedin-box-fill"></i></Link>
										<Link to='https://www.facebook.com/harisandconsulting'><i class="ri-facebook-circle-fill"></i></Link>
										<Link to='https://www.instagram.com/harisand.co/'><i class="ri-instagram-fill"></i></Link>
										<Link to=''><i class="ri-twitter-x-fill"></i></Link>

										
										
										
										
									</div>
								</div>

								</Form>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Chat;
