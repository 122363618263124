import React from 'react'
import thumbnail from '../../assets/thumbnail.png'
const Campaigns = () => {
  return (
		<div className="campaigns-main-box">
			<div className='container-main'>
				<div className="row">
					<div className="col-lg-3">
						<div className="campaign-dv">
							<h2 className='title-campaign'>Lorem Ipsum Dolor skit Amet</h2>
						</div>
					</div>
					<div className="col-lg-9">
						<div className="campaign-dvs">
							<img src={thumbnail} className='thumbnail-img' alt="" />
						</div>
					</div>
					<div className="col-lg-8">
						<div className="about-campaign">
							<p className='ptabut'>This is about shortlisting the right keywords, optimizing the URLs, correcting the title tags, and optimizing other elements on the website. It’s like tidying your house. This is about shortlisting the right keywords, optimizing the URLs, correcting the title tags, and optimizing other elements on the website. It’s like tidying your house.</p>
						</div>
					</div>
				</div>
			</div>
		</div>
  )
}
export default Campaigns;