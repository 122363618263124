import React from 'react'
import './peopleteam.css'
import Peopleimg from '../.././../assets/imagIndex'

export default function PeopleTeam() {
  return (
    <div className='peopleteam'>
        <div className='peopleteam-wrpper'>
            <div className='peopleteam-content'>
                <div className='peopleteam-content-hd'>
                    Our Team
                </div>
                <div className='peopleteam-content-subhd'>
                     People of haris&Co.
                </div>
            </div>
            <div className='peopleteam-imgwrpper peopleteam-imgwrpper-first'>
            <div className='peopleteam-imgitem'>
                    <img loading="lazy" src={Peopleimg.peopleTeam1} alt='Logo'/>  
                </div>
                <div className='peopleteam-imgitem'>
                    <img loading="lazy" src={Peopleimg.peopleTeam2} alt='Logo'/>  
                </div>
                <div className='peopleteam-imgitem'>
                    <img loading="lazy" src={Peopleimg.peopleTeam3} alt='Logo'/>  
                </div>
                <div className='peopleteam-imgitem'>
                    <img loading="lazy" src={Peopleimg.peopleTeam4} alt='Logo'/>  
                </div>
                <div className='peopleteam-imgitem'>
                    <img loading="lazy" src={Peopleimg.peopleTeam5} alt='Logo'/>  
                </div>
            </div>

            <div className='peopleteam-imgwrpper'>
            <div className='peopleteam-imgitem'>
                    <img loading="lazy" src={Peopleimg.peopleTeam6} alt='Logo'/>  
                </div>
                <div className='peopleteam-imgitem'>
                    <img loading="lazy" src={Peopleimg.peopleTeam7} alt='Logo'/>  
                </div>
                <div className='peopleteam-imgitem'>
                    <img loading="lazy" src={Peopleimg.peopleTeam8} alt='Logo'/>  
                </div>
                <div className='peopleteam-imgitem'>
                    <img loading="lazy" src={Peopleimg.peopleTeam9} alt='Logo'/>  
                </div>
                <div className='peopleteam-imgitem'>
                    <img loading="lazy" src={Peopleimg.peopleTeam10} alt='Logo'/>  
                </div>
            </div>
            
            <div className='peopleteam-imgwrpper'>
                 <div className='peopleteam-imgitem'>
                    <img loading="lazy" src={Peopleimg.peopleTeam11} alt='Logo'/>  
                </div>
                <div className='peopleteam-imgitem'>
                    <img loading="lazy" src={Peopleimg.peopleTeam12} alt='Logo'/>  
                </div>
                <div className='peopleteam-imgitem'>
                    <img loading="lazy" src={Peopleimg.peopleTeam13} alt='Logo'/>  
                </div>
                <div className='peopleteam-imgitem'>
                    <img loading="lazy" src={Peopleimg.peopleTeam14} alt='Logo'/>  
                </div>
                <div className='peopleteam-imgitem'>
                    <img loading="lazy" src={Peopleimg.peopleTeam15} alt='Logo'/>  
                </div>
            </div>
            
            <div className='peopleteam-imgwrpper'>
                 <div className='peopleteam-imgitem'>
                    <img loading="lazy" src={Peopleimg.peopleTeam16} alt='Logo'/>  
                </div>
                <div className='peopleteam-imgitem'>
                    <img loading="lazy" src={Peopleimg.peopleTeam17} alt='Logo'/>  
                </div>
                <div className='peopleteam-imgitem'>
                    <img loading="lazy" src={Peopleimg.peopleTeam18} alt='Logo'/>  
                </div>
                <div className='peopleteam-imgitem'>
                    <img loading="lazy" src={Peopleimg.peopleTeam19} alt='Logo'/>  
                </div>
                <div className='peopleteam-imgitem'>
                    <img loading="lazy" src={Peopleimg.peopleTeam20} alt='Logo'/>  
                </div>
            </div>

            <div className='peopleteam-imgwrpper'>
                 <div className='peopleteam-imgitem'>
                    <img loading="lazy" src={Peopleimg.peopleTeam21} alt='Logo'/>  
                </div>
                <div className='peopleteam-imgitem'>
                    <img loading="lazy" src={Peopleimg.peopleTeam22} alt='Logo'/>  
                </div>
                <div className='peopleteam-imgitem'>
                    <img loading="lazy" src={Peopleimg.peopleTeam23} alt='Logo'/>  
                </div>
                <div className='peopleteam-imgitem'>
                    <img loading="lazy" src={Peopleimg.peopleTeam24} alt='Logo'/>  
                </div>
                <div className='peopleteam-imgitem'>
                    <img loading="lazy" src={Peopleimg.peopleTeam25} alt='Logo'/>  
                </div>
            </div>

            <div className='peopleteam-imgwrpper'>
                 <div className='peopleteam-imgitem'>
                    <img loading="lazy" src={Peopleimg.peopleTeam26} alt='Logo'/>  
                </div>
                <div className='peopleteam-imgitem'>
                    <img loading="lazy" src={Peopleimg.peopleTeam27} alt='Logo'/>  
                </div>
                <div className='peopleteam-imgitem'>
                    <img loading="lazy" src={Peopleimg.peopleTeam28} alt='Logo'/>  
                </div>
                <div className='peopleteam-imgitem'>
                    <img loading="lazy" src={Peopleimg.peopleTeam29} alt='Logo'/>  
                </div>
                <div className='peopleteam-imgitem'>
                    <img loading="lazy" src={Peopleimg.peopleTeam30} alt='Logo'/>  
                </div>
            </div>

            <div className='peopleteam-imgwrpper'>
                 <div className='peopleteam-imgitem'>
                    <img loading="lazy" src={Peopleimg.peopleTeam31} alt='Logo'/>  
                </div>
                <div className='peopleteam-imgitem'>
                    <img loading="lazy" src={Peopleimg.peopleTeam32} alt='Logo'/>  
                </div>
                <div className='peopleteam-imgitem'>
                    <img loading="lazy" src={Peopleimg.peopleTeam33} alt='Logo'/>  
                </div>
                <div className='peopleteam-imgitem'>
                    <img loading="lazy" src={Peopleimg.peopleTeam34} alt='Logo'/>  
                </div>
                <div className='peopleteam-imgitem'>
                    <img loading="lazy" src={Peopleimg.peopleTeam35} alt='Logo'/>  
                </div>
            </div>

            <div className='peopleteam-imgwrpper'>
                 <div className='peopleteam-imgitem'>
                    <img loading="lazy" src={Peopleimg.peopleTeam36} alt='Logo'/>  
                </div>
                <div className='peopleteam-imgitem'>
                    <img loading="lazy" src={Peopleimg.peopleTeam37} alt='Logo'/>  
                </div>
                <div className='peopleteam-imgitem'>
                    <img loading="lazy" src={Peopleimg.peopleTeam38} alt='Logo'/>  
                </div>
                <div className='peopleteam-imgitem'>
                    <img loading="lazy" src={Peopleimg.peopleTeam39} alt='Logo'/>  
                </div>
                <div className='peopleteam-imgitem'>
                    <img loading="lazy" src={Peopleimg.peopleTeam40} alt='Logo'/>  
                </div>
            </div>

             <div className='peopleteam-imgwrpper'>
                 <div className='peopleteam-imgitem'>
                    <img loading="lazy" src={Peopleimg.peopleTeam41} alt='Logo'/>  
                </div>
                <div className='peopleteam-imgitem'>
                    <img loading="lazy" src={Peopleimg.peopleTeam42} alt='Logo'/>  
                </div>
                <div className='peopleteam-imgitem'>
                    <img loading="lazy" src={Peopleimg.peopleTeam43} alt='Logo'/>  
                </div>
                <div className='peopleteam-imgitem'>
                    <img loading="lazy" src={Peopleimg.peopleTeam44} alt='Logo'/>  
                </div>
                <div className='peopleteam-imgitem'>
                    <img loading="lazy" src={Peopleimg.peopleTeam45} alt='Logo'/>  
                </div>
            </div>

            <div className='peopleteam-imgwrpper'>
                 <div className='peopleteam-imgitem'>
                    <img loading="lazy" src={Peopleimg.peopleTeam46} alt='Logo'/>  
                </div>
                <div className='peopleteam-imgitem'>
                    <img loading="lazy" src={Peopleimg.peopleTeam47} alt='Logo'/>  
                </div>
                <div className='peopleteam-imgitem'>
                    <img loading="lazy" src={Peopleimg.peopleTeam48} alt='Logo'/>  
                </div>
                <div className='peopleteam-imgitem'>
                    <img loading="lazy" src={Peopleimg.peopleTeam49} alt='Logo'/>  
                </div>
                <div className='peopleteam-imgitem'>
                    <img loading="lazy" src={Peopleimg.peopleTeam50} alt='Logo'/>  
                </div>
            </div>

            <div className='peopleteam-imgwrpper'>
                 <div className='peopleteam-imgitem'>
                    <img loading="lazy" src={Peopleimg.peopleTeam51} alt='Logo'/>  
                </div>
                <div className='peopleteam-imgitem'>
                    <img loading="lazy" src={Peopleimg.peopleTeam52} alt='Logo'/>  
                </div>
                <div className='peopleteam-imgitem'>
                    <img loading="lazy" src={Peopleimg.peopleTeam53} alt='Logo'/>  
                </div>
                <div className='peopleteam-imgitem'>
                    <img loading="lazy" src={Peopleimg.peopleTeam54} alt='Logo'/>  
                </div>
                <div className='peopleteam-imgitem'>
                    <img loading="lazy" src={Peopleimg.peopleTeam55} alt='Logo'/>  
                </div>
            </div>

            <div className='peopleteam-imgwrpper'>
                 <div className='peopleteam-imgitem'>
                    <img loading="lazy" src={Peopleimg.peopleTeam56} alt='Logo'/>  
                </div>
                <div className='peopleteam-imgitem'>
                    <img loading="lazy" src={Peopleimg.peopleTeam57} alt='Logo'/>  
                </div>
                <div className='peopleteam-imgitem'>
                    <img loading="lazy" src={Peopleimg.peopleTeam58} alt='Logo'/>  
                </div>
                <div className='peopleteam-imgitem'>
                    <img loading="lazy" src={Peopleimg.peopleTeam59} alt='Logo'/>  
                </div>
                <div className='peopleteam-imgitem'>
                    <img loading="lazy" src={Peopleimg.peopleTeam60} alt='Logo'/>  
                </div>
            </div>

            <div className='peopleteam-imgwrpper'>
                 <div className='peopleteam-imgitem'>
                    <img loading="lazy" src={Peopleimg.peopleTeam61} alt='Logo'/>  
                </div>
                <div className='peopleteam-imgitem'>
                    <img loading="lazy" src={Peopleimg.peopleTeam62} alt='Logo'/>  
                </div>
                <div className='peopleteam-imgitem'>
                    <img loading="lazy" src={Peopleimg.peopleTeam63} alt='Logo'/>  
                </div>
                <div className='peopleteam-imgitem'>
                    <img loading="lazy" src={Peopleimg.peopleTeam64} alt='Logo'/>  
                </div>
                <div className='peopleteam-imgitem'>
                    <img loading="lazy" src={Peopleimg.peopleTeam65} alt='Logo'/>  
                </div>
            </div>

        </div>    
    </div>
  )
}
